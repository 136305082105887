import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewState } from 'app/models/app';
import {
    CompanyDto,
    ExternalJobDto,
    JobBoardProductCreationDto,
    JobPostingDto,
    MatchedLeadsProductDto,
} from 'app/models/dtos';
import { JobBoardPostingTransforms } from 'app/transforms/jobBoardPostingTransforms';
import { environment } from 'environments/environment';
import * as moment from 'moment';

@Component({
    selector: 'app-job-board-posting-create-product-modal',
    templateUrl: './job-board-posting-create-product-modal.component.html',
    // styleUrls: ['./job-board-posting-create-product-modal.component.css']
})
export class JobBoardPostingCreateProductModalComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<JobBoardPostingCreateProductModalComponent>,
        @Inject(MAT_DIALOG_DATA) public jobPosting: JobPostingDto,
        private http: HttpClient
    ) {}

    //vars
    moment = moment;

    //view states
    viewStates = ViewState;
    viewState = ViewState.content;

    //vars
    companies: CompanyDto[] = [];
    selectedCompanyId: string;

    products: MatchedLeadsProductDto[] = [];
    selectedProductId: string;

    ngOnInit() {
        this.getCompanies();
        this.getProducts();
    }

    //api
    getCompanies(): void {
        this.viewState = ViewState.loading;

        this.http
            .get(`${environment.services_tdusa_admin}/v1/companies`)
            .subscribe((result: CompanyDto[]) => {
                this.companies = result;

                //attempt to auto-select for known companies
                this.selectedCompanyId = this.companies.find(
                    (p) => p.name == this.jobPosting?.jobPosting?.Company
                )?.id;

                this.viewState = ViewState.content;
            });
    }

    getProducts(): void {
        this.viewState = ViewState.loading;

        this.http
            .get(`${environment.services_tdusa_admin}/v1/matchedLeadsProducts`)
            .subscribe((result: MatchedLeadsProductDto[]) => {
                this.products = result;

                //attempt to auto-select for known products
                this.selectedProductId = this.products.find(
                    (p) => p.name == this.jobPosting?.jobPosting?.Company
                )?.id;

                this.viewState = ViewState.content;
            });
    }

    selectedCompany(): CompanyDto {
        return this.companies.find((c) => c.id == this.selectedCompanyId);
    }

    selectedProduct(): MatchedLeadsProductDto {
        return this.products.find((p) => p.id == this.selectedProductId);
    }

    createCompany(): void {
        //build creation
        const creation = this.creation();
        creation.product = this.mlp();

        //make request
        this.viewState = ViewState.loading;
        this.http
            .post(
                `${environment.services_tdusa_admin}/v1/jobBoards/${this.jobPosting.id}/companies`,
                creation
            )
            .subscribe((company: CompanyDto) => {
                this.viewState = ViewState.content;

                this.didClickDismiss();

                //reload full page
                window.location.reload();
            });
    }

    createProduct(): void {
        //build creation
        const creation = this.creation();
        creation.product = this.mlp();

        //make request
        this.viewState = ViewState.loading;
        this.http
            .post(
                `${environment.services_tdusa_admin}/v1/jobBoards/${this.jobPosting.id}/products`,
                creation
            )
            .subscribe((product: MatchedLeadsProductDto) => {
                this.viewState = ViewState.content;

                this.didClickDismiss();

                //reload full page
                window.location.reload();
            });
    }

    addToProduct(creation: JobBoardProductCreationDto): void {
        //make request
        this.viewState = ViewState.loading;
        this.http
            .post(
                `${environment.services_tdusa_admin}/v1/jobBoards/${this.jobPosting.id}/jobs`,
                this.creation()
            )
            .subscribe((job: ExternalJobDto) => {
                this.viewState = ViewState.content;

                this.didClickDismiss();

                //reload full page
                window.location.reload();
            });
    }

    //actions
    didClickDismiss(action: string = 'dismiss') {
        this.dialogRef.close({
            action: action,
            data: this.jobPosting,
        });
    }

    mlp(): MatchedLeadsProductDto {
        return JobBoardPostingTransforms.postingToMlp(this.jobPosting);
    }

    job(): ExternalJobDto {
        return JobBoardPostingTransforms.postingToJob(this.jobPosting);
    }

    creation() : JobBoardProductCreationDto {
        const creation = new JobBoardProductCreationDto();
        creation.company = this.selectedCompany();
        creation.product = this.selectedProduct();
        creation.externalJob = this.job();

        return creation;
    }
}
