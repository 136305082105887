import { EbeJobPosting, TenstreetJobPosting } from 'app/models/app';
import {
    ExternalJobDto,
    ExternalJobPositionDto,
    HiringCityDto,
    HiringZipDto,
    JobPostingDto,
    MatchedLeadsProductDto,
    ProductSpendPeriodDto,
} from 'app/models/dtos';
import {
    CdlClassesLookup,
    DriverTypesLookup,
    EndorsementTypesLookup,
    ExperienceTypesLookup,
    FreightTypesLookup,
    JobBoardTypesLookup,
} from 'app/models/lookups';
import * as moment from 'moment';

export class JobBoardPostingTransforms {
    static postingToMlp(
        jobBoardPosting: JobPostingDto
    ): MatchedLeadsProductDto {
        switch (jobBoardPosting.jobPostingType.id) {
            case JobBoardTypesLookup.TENSTREET.id:
                return this.tenstreetPostingToMlp(jobBoardPosting.jobPosting);
            case JobBoardTypesLookup.EBE.id:
                return this.ebePostingToMlp(jobBoardPosting.jobPosting);
            default:
                return null;
        }
    }

    static postingToJob(jobBoardPosting: JobPostingDto): ExternalJobDto {
        switch (jobBoardPosting.jobPostingType.id) {
            case JobBoardTypesLookup.TENSTREET.id:
                return this.tenstreetPostingToJob(jobBoardPosting.jobPosting);
            case JobBoardTypesLookup.EBE.id:
                return this.ebePostingToJob(jobBoardPosting.jobPosting);
            default:
                return null;
        }
    }

    //#region tenstreet
    private static tenstreetPostingToMlp(
        jobBoardPosting: TenstreetJobPosting
    ): MatchedLeadsProductDto {
        const product = new MatchedLeadsProductDto();
        product.name = jobBoardPosting.Company;
        product.tenstreetCompanyId = `${jobBoardPosting.CompanyId}`;
        try {
            product.tenstreetReferrer = this.getQueryParam(jobBoardPosting.ApplyLinkURL, 'r');
        } catch (error) {  }
        
        //attach spend period, if available
        //removed 3/3/25 per Lori / Katy guidance to force human review
        // if (jobBoardPosting.Budget && jobBoardPosting.StartDate && jobBoardPosting.EndDate) {
        //     product.currentSpendPeriod = new ProductSpendPeriodDto();
        //     product.currentSpendPeriod.spendInCents = jobBoardPosting.Budget*100;
        //     product.currentSpendPeriod.timestampStart = moment(jobBoardPosting.StartDate).toDate();
        //     product.currentSpendPeriod.timestampEnd = moment(jobBoardPosting.EndDate).toDate();
        //     product.currentSpendPeriod.allocationPercentage = 50;
        // }
        
        return product;
    }

    private static tenstreetPostingToJob(
        jobBoardPosting: TenstreetJobPosting
    ): ExternalJobDto {
        const job = new ExternalJobDto();
        job.title = jobBoardPosting.JobTitle;
        job.descriptionHtml = jobBoardPosting.JobDescription;
        job.timestampPosted = new Date();
        job.salary = jobBoardPosting.JobPayRange;
        job.tenstreetJobPostingReferenceId = jobBoardPosting.ReferenceId;

        //qualifications
        job.cdlClasses = this.cdlClassesForTenstreetJob(jobBoardPosting);
        job.driverTypes = this.driverTypesForTenstreetJob(jobBoardPosting);
        job.freightTypes = this.freightTypeForTenstreetJob(jobBoardPosting);
        job.experienceType = this.experienceTypeForTenstreetJob(jobBoardPosting);

        //positions
        job.positions = this.positionsForTenstreetJob(jobBoardPosting);

        return job;
    }

    private static cdlClassesForTenstreetJob(
        jobBoardPosting: TenstreetJobPosting
    ): CdlClassesLookup[] {
        const filterValue = jobBoardPosting.JobFilters.JobFilter.find(
            (filter) => filter.Filter === 'license_class'
        )?.Value;

        switch (filterValue) {
            case 'class_a':
                return [CdlClassesLookup.CLASS_A];
            case 'class_b':
                return [CdlClassesLookup.CLASS_B];
            case 'class_a':
                return [CdlClassesLookup.CLASS_C];
            default:
                return [];
        }
    }

    private static driverTypesForTenstreetJob(
        jobBoardPosting: TenstreetJobPosting
    ): DriverTypesLookup[] {
        const filterValue = jobBoardPosting.JobFilters.JobFilter.find(
            (filter) => filter.Filter === 'driver_type'
        )?.Value;

        switch (filterValue) {
            case 'Company Driver':
                return [DriverTypesLookup.COMPANY_DRIVER];
            case 'Owner Operator':
                return [DriverTypesLookup.LEASE_PURCHASE];
            case 'Lease Purchase':
                return [DriverTypesLookup.OWNER_OPERATOR];
            default:
                return [];
        }
    }

    private static freightTypeForTenstreetJob(
        jobBoardPosting: TenstreetJobPosting
    ): FreightTypesLookup[] {
        const filterValue = jobBoardPosting.JobFilters.JobFilter.find(
            (filter) => filter.Filter === 'equipment'
        )?.Value;

        switch (filterValue) {
            case 'Van':
                return [FreightTypesLookup.DRY_VAN];
            case 'Reefer':
                return [FreightTypesLookup.REFRIGERATED];
            case 'Flatbed':
                return [FreightTypesLookup.FLATBED];
            case 'Tanker':
                return [FreightTypesLookup.TANKER];
            default:
                return [];
        }
    }

    private static experienceTypeForTenstreetJob(
        jobBoardPosting: TenstreetJobPosting
    ): ExperienceTypesLookup {
        const filterValue = jobBoardPosting.JobFilters.JobFilter.find(
            (filter) => filter.Filter === 'driver_experience'
        )?.Value;

        switch (filterValue) {
            case 'cdl_no_exp':
                return ExperienceTypesLookup.CDL_GRAD_NO_EXPERIENCE;
            case '1+_year':
                return ExperienceTypesLookup.TWELVE_TO_TWENTY_THREE_MONTHS;
            case '2+_years':
                return ExperienceTypesLookup.TWO_YEARS;
            case '3+_years':
                return ExperienceTypesLookup.THREE_YEARS;
            case '4+_years':
                return ExperienceTypesLookup.FOUR_YEARS;
            case '5+_years':
                return ExperienceTypesLookup.FIVE_YEARS;
            default:
                return null;
        }
    }

    private static positionsForTenstreetJob(
        jobBoardPosting: TenstreetJobPosting
    ): ExternalJobPositionDto[] {
        return jobBoardPosting.Locations.Location.map(location => {
            const jobPosition = new ExternalJobPositionDto();
            
            //hiring city
            if((location.City ?? '').trim().length > 0) {
                jobPosition.hiringCity = new HiringCityDto();
                jobPosition.hiringCity.name = location.City
            }
            
            //hiring zip code
            if(location.PostalCode) {
                jobPosition.hiringZip = new HiringZipDto();
                jobPosition.hiringZip.name = `${location.PostalCode}`;
            }

            return jobPosition;
        });
    }

    private static endorsementsForTenstreetJob(
        jobBoardPosting: TenstreetJobPosting
    ): CdlClassesLookup[] {
        const endorsements: EndorsementTypesLookup[] = [];

        //Twic
        for (const endorsement of jobBoardPosting.Endorsements.Endorsement) {
            switch (endorsement.Type) {
                case 'tank_endorsements':
                    //not enough info 2025/02/19
            
                default:
                    break;
            }
        }

        //Twic
        const twicFilterValue = jobBoardPosting.JobFilters.JobFilter.find(
            (filter) => filter.Filter === 'twic_required'
        )?.Value;
        if((twicFilterValue ?? '').includes('yes')) {
            endorsements.push(EndorsementTypesLookup.TWIC_CARD);
        }

        return endorsements;
    }
    //#endregion

    private static ebePostingToJob(
        jobBoardPosting: EbeJobPosting
    ): ExternalJobDto {
        const job = new ExternalJobDto();

        return job;
    }

    private static ebePostingToMlp(
        jobBoardPosting: EbeJobPosting
    ): MatchedLeadsProductDto {
        const product = new MatchedLeadsProductDto();

        return product;
    }

    //#region helpers
    static getQueryParam(url: string, paramName: string): string | null {
        try {
          const urlObject = new URL(url);
          const searchParams = new URLSearchParams(urlObject.search);
          return searchParams.get(paramName);
        } catch (error) {
          console.error("Invalid URL:", error);
          return null;
        }
      }
    //#endregion
}
