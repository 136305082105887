import { CompanyDto } from "./company.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { LeadDto } from "./lead.dto";
import { ApplicationContactTimeDto, CdlClassDto, DriverTypeDto, EndorsementTypeDto, ExperienceTypeDto, FreightTypeDto, HiringStateDto, HiringZipDto } from "./lookup.dto";

export class ApplicationDto extends IdentifiableDto {
    timestampCreated!: Date;
    timestampUpdated!: Date;
    firstName!: string;
    lastName!: string;
    email!: string;
    phone!: string;
    city!: string;
    latitude!: number;
    longitude!: number;
    teamDriver!: boolean;
    militaryExperience!: boolean;
    interestInOoLp!: boolean;
    interestInTeamDriving!: boolean;
    hasOwnAuthority!: boolean;
    violations!: number;
    accidents!: number;
    blocked!: boolean;
    suspicious!: boolean;
    ipAddress: string;

    //utm
    utmSource!: string;
    utmMedium!: string;
    utmCampaign!: string;

    hiringState: HiringStateDto;
    hiringZip!: HiringZipDto;
    experienceType!: string;
    freightType!: string;
    driverType!: string;
    applicationContactTime!: string;
    company: CompanyDto;
    leads: LeadDto[] = [];

    //many-to-many
    cdlClasses: string[] = [];
    endorsements: string[] = [];
    interestedFreightTypes: string[] = [];
}