import { HttpClient } from '@angular/common/http';
import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ViewState } from 'app/models/app';
import { PieChartDataPair } from 'app/models/app/charts/pieChartDataPair';
import { StackedChartAppSet } from 'app/models/app/charts/stackedChartAppSet';
import { DateRanges } from 'app/models/app/dateRanges';
import { LeadRadius } from 'app/models/app/leadRadius';
import { AbbreviatedLookupDto, AppAnalyticsDto, ExternalJobPositionDto, DriverTypeDto, JobAnaylyticsDto } from 'app/models/dtos';
import { AppAnalyticDateContainer, AppAnalyticPair } from 'app/models/dtos/appAnalytics2.dto';
import { AppAnalytics3Dto } from 'app/models/dtos/appAnalytics3.dto';
import { CsvImportRow, CsvImportRowMappers } from 'app/models/integrations/csv/csvImportRow';
import { CdlClassesLookup, DriverTypesLookup, ExperienceTypesLookup, FreightTypesLookup } from 'app/models/lookups';
import { HiringStatesLookup } from 'app/models/lookups/hiringStates.lookup';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { environment } from 'environments/environment';
import { AnySourceData, Map, NavigationControl, Point } from 'mapbox-gl';
import * as moment from 'moment';
import { ApexAxisChartSeries, ApexOptions, ApexXAxis } from 'ng-apexcharts';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { TdusaMapElements } from '../../maps/tdusa-map-elements';
import { LeadRadiusComponent } from '../../leads/leads-analytics/lead-radius/lead-radius.component';
import { SelectStatesModalComponent, SelectStatesModalComponentInput } from '../../common/select-states-modal/select-states-modal.component';
import { saveAs } from 'file-saver';
import { JobSliceTypes } from 'app/models/app/jobSliceTypes';
import { AnalyticsLocationDto } from 'app/models/dtos/analyticsLocation.dto';
import { FeatureCollection } from '@turf/turf';

@Component({
  selector: 'app-jobs-analytics',
  templateUrl: './jobs-analytics.component.html',
  styleUrls: ['./jobs-analytics.component.css']
})
export class JobsAnalyticsComponent implements OnInit {
  constructor(private http: HttpClient, public dialog: MatDialog, private ngZone: NgZone) { }

  //inputs
  @Input() jobRoute: string;
  @Input() permissionsService: PermissionsService;

  //view children
  @ViewChild('jobsTable', { read: MatSort, static: false }) jobsTableMatSort: MatSort;

  //vars
  jobs: ExternalJobPositionDto[] = [];
  _filteredJobs: ExternalJobPositionDto[] = null;
  // appAnalytics: AppAnalyticsDto[] = [];
  appAnalytics3: JobAnaylyticsDto = new JobAnaylyticsDto();
  locations: AnalyticsLocationDto[] = [];
  _mapFilteredLocations: AnalyticsLocationDto[] = [];
  _filteredAppAnalytics: AppAnalyticsDto[] = null; //cache
  topMarkets: AppAnalyticPair[] = [];

  moment = moment;
  selectedHiringState = HiringStatesLookup.ALL.id;
  selectedHiringStates: HiringStatesLookup[] = [];
  selectedSliceType: JobSliceTypes = JobSliceTypes.STATE;
  overTimeSliced: boolean = false;
  selectedTopMarketsType: string = 'city';

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  exportViewState = ViewState.content;
  mapViewState = ViewState.loading;

  //type lists
  driverTypes = structuredClone(DriverTypesLookup.values);
  cdlClasses = structuredClone(CdlClassesLookup.values);
  experienceTypes = structuredClone(ExperienceTypesLookup.values);
  freightTypes = structuredClone(FreightTypesLookup.values);
  hiringStates: HiringStatesLookup[] = structuredClone(HiringStatesLookup.values);

  //raw types
  experienceTypesRaw = ExperienceTypesLookup;
  freighTypesRaw = FreightTypesLookup;
  cdlClassesRaw = CdlClassesLookup;
  driverTypesRaw = DriverTypesLookup;

  //charts
  jobSliceTypes: JobSliceTypes[] = structuredClone(JobSliceTypes.values);
  chartAppsOverTime: ApexOptions;
  chartUtmSources: ApexOptions;
  _slicePieData: PieChartDataPair[];
  _sliceChartData: ApexAxisChartSeries;
  chartPie: ApexOptions;

  //map
  map: Map;
  hasLoadedZipsSource: boolean = false;
  leadRadius: LeadRadius = null;

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.getResults();
  }

  //api
  getResults() {
    // this.getJobs();
    this.getJobAnalytics();
    this.getJobTopMarkets();
  }
  getJobs(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/${this.jobRoute}`, {
        params: this.jobsQueryString()
      })
      .subscribe((result: ExternalJobPositionDto[]) => {
        this.jobs = result;
        // for (let index = 0; index < 12; index++) {
        //   this.jobs = this.jobs.concat(this.jobs);
        // }
        this.viewState = ViewState.content;
        this.invalidateStats();
      });
  }

  getJobAnalytics(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/${this.jobRoute}-analytics`, {
        params: this.jobsQueryString()
      })
      .subscribe((result: JobAnaylyticsDto) => {
        this.appAnalytics3 = result;
        this.invalidateStats();
        // this.invalidateCharts();
      });
  }

  getJobLocations(): void {
    // check for cache hit first
    if (this.locations.length > 0) {
      this.updateMapZips();
      return;
    }

    // otherwise fetch
    this.mapViewState = ViewState.loading;
    this.http
      .get(`${environment.services_tdusa_admin}/${this.jobRoute}-locations`, {
        params: this.jobsQueryString()
      })
      .subscribe((result: AnalyticsLocationDto[]) => {
        this.locations = result;

        //render locations
        this.updateMapZips();
        this.filterMapLocations();

        this.mapViewState = ViewState.content;
      });
  }

  getJobTopMarkets(): void {
    this.viewState = ViewState.loading;

    //build query
    const params = this.jobsQueryString();
    params.type = this.selectedTopMarketsType;

    this.http
      .get(`${environment.services_tdusa_admin}/${this.jobRoute}-topmarkets`, {
        params: params
      })
      .subscribe((result: AppAnalyticPair[]) => {
        this.topMarkets = result;
      });
  }

  jobsQueryString(): any {

    var params: any = {};

    params.limit = 20;

    //state filter
    if (this.selectedHiringStates.length > 0) {
      params.state = this.selectedHiringStates.filter(t => t.checked).map(t => t.id);
    }
    else {
      delete params.state;
    }

    //radius filter
    if (this.leadRadius != null) {
      params.radius = this.leadRadius.radius;
      params.lat = this.leadRadius.lngLat.lat;
      params.lng = this.leadRadius.lngLat.lng;
    } else {
      delete params.radius;
      delete params.lat;
      delete params.lng;
    }

    //type filters
    if (this.driverTypes.filter(t => t.checked).length > 0) {
      params.dt = this.driverTypes.filter(t => t.checked).map(t => t.id);
    } else { delete params.dt; }
    if (this.cdlClasses.filter(t => t.checked).length > 0) {
      params.lic = this.cdlClasses.filter(t => t.checked).map(t => t.id);
    } else { delete params.lic; }
    if (this.experienceTypes.filter(t => t.checked).length > 0) {
      params.exp = this.experienceTypes.filter(t => t.checked).map(t => t.id);
    } else { delete params.exp; }
    if (this.freightTypes.filter(t => t.checked).length > 0) {
      params.ft = this.freightTypes.filter(t => t.checked).map(t => t.id);
    } else { delete params.ft; }

    return params;
  }

  seachTextDidChange(text: string) {
    if (text.length > 0 && text.length < 3) { return; }
    this.getResults();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  serializedLookups(lookups: string[], types: any): string {
    return lookups.map(l => types.fromId(l).name).join(', ');
  }

  serializedAbbreviatedLookups(lookups: AbbreviatedLookupDto[]): string {
    return lookups.map(l => l.abbreviation).join(', ');
  }

  //stats/filters
  invalidateStats() {
    this._filteredAppAnalytics = null;
    this._filteredJobs = null;
    this.invalidateCharts();
    if (this.map != null) {
      this.locations = [];
      this.getJobLocations();
      this.updateMapZips();
    }
  }


  states(type: HiringStatesLookup): number {
    return this.appAnalytics3.states
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  driverTypeTotal(type: DriverTypesLookup): number {
    return this.appAnalytics3.driverTypes
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  cdlClassTotal(type: CdlClassesLookup): number {
    return this.appAnalytics3.cdlClasses
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  experienceTypeTotal(type: ExperienceTypesLookup): number {
    return this.appAnalytics3.experienceTypes
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  freightTypeTotal(type: DriverTypeDto): number {
    return this.appAnalytics3.freightTypes
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  //chart
  invalidateCharts() {
    //invalidate local caches
    this._slicePieData = null;
    this._sliceChartData = null;

    //rebuild charts
    this.buildCharts();
  }

  buildCharts() {
    this.chartAppsOverTime = {
      series: this.overTimeSliced ? this.slicedChartSeries(this.selectedSliceType) : this.chartSeries(this.selectedSliceType),
      chart: {
        type: 'line',
        height: 400,
        width: 400,
        stacked: false,
        toolbar: {
          show: false
        }
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          },
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        },
      },
      xaxis: this.chartXAxis(),
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    };

    this.pieChartOptions();

    // this.chartUtmSources = {
    //   series: this.sliceChartData(this.selectedSliceType).map(t => t.value),
    //   chart: {
    //     width: '100%',
    //     height: 400,
    //     type: 'donut',
    //   },
    //   labels: this.sliceChartData(this.selectedSliceType).map(t => t.label),
    //   legend: {
    //     position: 'bottom',
    //     offsetY: 40,
    //     show: false
    //   }
    // };
    // const asdf = this.sliceChartData(this.selectedSliceType);
  }

  selectSliceType(type: JobSliceTypes) {
    this.selectedSliceType = type;
    this.invalidateCharts();
  }

  sliceChartData(type: JobSliceTypes): PieChartDataPair[] {
    //check cache
    if (this._slicePieData != null) { return this._slicePieData; }

    //get solo types
    this._slicePieData = this.typedSlices(JobSliceTypes.fromId(type.id));

    //clean up nulls
    this._slicePieData.forEach(s => {
      if (s.label == 'null') { s.label = 'N/A'; }
    })

    return this._slicePieData;
  }

  flatSlices(key: string): PieChartDataPair[] {
    return [];
    // const pairMap = this.appAnalytics2.flatMap(a => a.analytic[key]).reduce((acc, curr) => {
    //   if (!acc[curr.id]) acc[curr.id] = curr.amount; //If this type wasn't previously stored
    //   else { acc[curr.id] += curr.amount; }
    //   return acc;
    // }, {});
    // return Object.entries(pairMap).map(e => new PieChartDataPair(e[0], e[1] as number));
  }

  typedSlices(type: JobSliceTypes): PieChartDataPair[] {
    const key = JobSliceTypes.fromId(type.id).propName;

    const pairMap = this.appAnalytics3[key];
    return Object.entries<AppAnalyticPair>(pairMap).map(e => {
      if (type.id == JobSliceTypes.STATE.id) {
        if (HiringStatesLookup.fromId(e[0]) == null) { return new PieChartDataPair('N/A', 0); } //backward compat. check. Needed because we restricted hiring states after release
        return new PieChartDataPair(HiringStatesLookup.fromId(e[0]).abbreviation, e[1].amount as number);
      }
      else if (type.id == JobSliceTypes.DRIVER_TYPE.id) {
        return new PieChartDataPair(DriverTypesLookup.fromId(e[1].id).name, e[1].amount as number);
      }
      else if (type.id == JobSliceTypes.CDL_CLASSES.id) {
        return new PieChartDataPair(CdlClassesLookup.fromId(e[1].id).name, e[1].amount as number);
      }
      else if (type.id == JobSliceTypes.EXPERIENCE_TYPE.id) {
        return new PieChartDataPair(ExperienceTypesLookup.fromId(e[1].id).name, e[1].amount as number);
      }
      else if (type.id == JobSliceTypes.FREIGHT_TYPE.id) {
        return new PieChartDataPair(FreightTypesLookup.fromId(e[1].id).name, e[1].amount as number);
      }
    });
  }

  chartSeries(type: JobSliceTypes): ApexAxisChartSeries {
    return null;
    // //check cache
    // if (this._sliceChartData != null) { return this._sliceChartData; }

    // //flattened total
    // const totalSet = new StackedChartAppSet('Total', this.appAnalytics2);

    // //build range
    // const buckets = this.appAnalytics2
    //   .map(a => a.analytic.total);

    // const chartSeries: ApexAxisChartSeries = [{
    //   name: totalSet.label,
    //   data: buckets,

    // }];
    // this._sliceChartData = chartSeries;
    // return chartSeries;
  }

  slicedChartSeries(type: JobSliceTypes): ApexAxisChartSeries {
    var appSets2 = {};
    const propName = JobSliceTypes.fromId(type.id).propName;
    //collect all keys for the slyce type
    this.appAnalytics3[propName].forEach(analytics => {
      (analytics.analytic[propName] as AppAnalyticPair[]).forEach(p => appSets2[p.id] = p.amount);
    });

    //iterate over all keys and form buckets (i.e. series) for each key
    const chartSeries: ApexAxisChartSeries = Object.keys(appSets2).map(key => {
      var seriesName = key; //defaults to solo
      const buckets = this.appAnalytics3[propName].map(analytics => {
        return (analytics.analytic[propName] as AppAnalyticPair[]).find(p => p.id == key)?.amount ?? 0;
      });

      //generate series name from type-based id
      if (type.id == JobSliceTypes.STATE.id) {
        if (HiringStatesLookup.fromId(key) != null) {
          seriesName = HiringStatesLookup.fromId(key).abbreviation;
        } //backward compat. check. Needed because we restricted hiring states after release
        else {
          seriesName = 'N/A';
        }
      }
      else if (type.id == JobSliceTypes.DRIVER_TYPE.id) {
        seriesName = DriverTypesLookup.fromId(key).name;
      }
      else if (type.id == JobSliceTypes.CDL_CLASSES.id) {
        seriesName = CdlClassesLookup.fromId(key).name;
      }
      else if (type.id == JobSliceTypes.EXPERIENCE_TYPE.id) {
        seriesName = ExperienceTypesLookup.fromId(key).name;
      }
      else if (type.id == JobSliceTypes.FREIGHT_TYPE.id) {
        seriesName = FreightTypesLookup.fromId(key).name;
      }

      return {
        name: seriesName,
        data: buckets,
      }
    });

    this._sliceChartData = chartSeries;
    return chartSeries;
  }

  chartXAxis(): ApexXAxis {
    return null
    // const ticks = this.appAnalytics2.map(a => `${a.anchor}`);
    // return {
    //   categories: ticks,
    //   tickPlacement: 'on'
    // }
  }

  pieChartOptions() {
    this.chartPie = {
      series: this.sliceChartData(this.selectedSliceType).map(t => t.value),
      chart: {
        width: 400,
        height: 400,
        type: 'pie',
      },
      labels: this.sliceChartData(this.selectedSliceType).map(t => t.label),
      legend: {
        position: 'bottom',
        offsetY: 40,
        show: false
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
              height: 300,
            },
          }
        },
        {
          breakpoint: 720,
          options: {
            chart: {
              width: 300,
              height: 300,
            },
          }
        }
      ]
    };
  }

  //Export
  downloadJobsCsv() {
    this.exportViewState = ViewState.loading;

    //make a csv and download
    try {
      var blob = new Blob([this.csvFromJobs(this.topMarkets)], { type: 'text/csv' })
      saveAs(blob, `Jobs Report (Popular Markets) ${new Date()}.csv`);
    } catch (error) {

    }

    this.exportViewState = ViewState.content;
  }

  csvFromJobs(jobs: AppAnalyticPair[]): string {

    const rows = jobs
    const fields = Object.keys(rows[0] ?? new CsvImportRow());

    const replacer = function (key, value) { return value === null ? '' : value }
    const csv = rows.map(row => {
      return fields.map(fieldName => {
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    })
    csv.unshift(fields.join(',')) // add header column
    const csvString = csv.join('\r\n');
    // console.log(csv)
    return csvString
  }

  //map
  onMapLoad(event) {
    this.map = event;

    // Add zoom and rotation controls to the map.
    this.map.addControl(new NavigationControl());

    //get locations
    this.getJobLocations();
    this.filterMapLocations();

    this.map.on('move', () => {
      this.ngZone.run(() => {
        this.filterMapLocations();
      });
    });

    // inspect a cluster on click
    this.map.on('click', 'clusters', (e) => {
      // const feature = e.features[0];
      // const clusterId = feature.properties.cluster_id;
      // const clusterSource = this.map.getSource(feature.source) as mapboxgl.GeoJSONSource;

      // // for some reason I don't get `coordinates` on `feature.geometry` via typing, but it exists at runtime.
      // const clusterCoordinates = { lat: feature.geometry['coordinates'][0], lng: feature.geometry['coordinates'][1] };

      // clusterSource.getClusterExpansionZoom(clusterId, (err: Error, zoom: number) => {
      //   this.map.easeTo({ center: clusterCoordinates, zoom: zoom + 2 });
      // });



      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      const clusterId = features[0].properties.cluster_id;


      (this.map.getSource(TdusaMapElements.jobsLocationsSourceTag) as mapboxgl.GeoJSONSource).getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return;
          
          this.map.easeTo({
            center: (features[0].geometry as any).coordinates,
            zoom: zoom
          });
        }
      );
    });
  }

  updateMapZips() {
    //remove old source
    if (this.hasLoadedZipsSource) {
      this.map.removeLayer('clusters');
      this.map.removeLayer('cluster-count');
      this.map.removeLayer('unclustered-point');
      this.map.removeSource(TdusaMapElements.jobsLocationsSourceTag);
    }

    this.map.addSource(TdusaMapElements.jobsLocationsSourceTag, this.zipsMapSource(this.locations.filter(p => p.lat != null && p.lng != null)));

    this.map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: TdusaMapElements.jobsLocationsSourceTag,
      filter: ['has', 'point_count'],
      paint: {
        // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      }
    });

    this.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: TdusaMapElements.jobsLocationsSourceTag,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });

    this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: TdusaMapElements.jobsLocationsSourceTag,
        filter: ['!', ['has', 'point_count']],
        paint: {
            'circle-color': '#11b4da',
            'circle-radius': 4,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
        }
    });



    // this.map.addLayer(TdusaMapElements.jobsZipsLayer(), this.map.getStyle().layers[1].id);

    this.hasLoadedZipsSource = true;
  }

  zipsMapSource(positions: AnalyticsLocationDto[]): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: positions.map(z => TdusaMapElements.pointMarkerForLatLng(z.lat, z.lng))
      },
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    }
  }

  tabClick(tabEvent: any) {
    if (tabEvent.tab.textLabel === 'Charts') {
      this.hasLoadedZipsSource = false;
      this.map = null;
      this.invalidateCharts();
    }
  }

  sum(numbers: PieChartDataPair[]): number {
    if (numbers == null) { return 0; }

    return numbers.reduce((partialSum, a) => partialSum + a.value, 0);
  }

  sumAnalyticsPairs(pairs: AppAnalyticPair[]): number {
    if (pairs == null) { return 0; }

    return pairs.reduce((partialSum, a) => partialSum + a.amount, 0);
  }

  rankedSliceData(data: PieChartDataPair[]): PieChartDataPair[] {
    if (data == null) { return []; }
    if (data.length == 0) { return []; }

    return data.sort((a: PieChartDataPair, b: PieChartDataPair) => {
      if (a.value > b.value) { return -1; }
      else if (a.value < b.value) { return 1; }
      else { return 0; }
    });
  }

  //radius
  didClickSetRadius() {
    const dialogRef = this.dialog.open(LeadRadiusComponent, {
      data: this.leadRadius,
      maxHeight: '100%',
      height: '768px',
      width: '768px',
      minWidth: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      const radius: LeadRadius = result.data;
      switch (result.action) {
        case 'cancel':
          this.didClickClearRadius();

          break;
        case 'remove':
          this.didClickClearRadius();
          break;
        case 'confirm':
          this.didClickConfirmRadius(radius);
          //search with radius
          break;
        default:
          break;
      }
      this.invalidateStats();
    });
  }

  didClickClearRadius() {
    this.leadRadius = null;
    this.getResults();
  }

  didClickConfirmRadius(radius: LeadRadius) {
    this.leadRadius = radius;
    this.getResults();
  }

  showSelectStates() {
    //build input
    const input = new SelectStatesModalComponentInput();
    input.states = this.selectedHiringStates;

    //show modal
    const dialogRef = this.dialog.open(SelectStatesModalComponent, {
      data: input,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) { return; }

      const states: HiringStatesLookup[] = result;
      this.selectedHiringStates = states;

      this.getResults();
    });
  }

  filterMapLocations() {
    if (this.map == null) { return this.locations; }

    this._mapFilteredLocations = this.locations
      .filter(l =>
        l.lat <= this.map.getBounds().getNorth()
        && l.lat >= this.map.getBounds().getSouth()
        && l.lng >= this.map.getBounds().getWest()
        && l.lng <= this.map.getBounds().getEast()
      );
  }
}
