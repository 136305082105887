import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { PieChartDataPair } from 'app/models/app/charts/pieChartDataPair';
import { DateRanges } from 'app/models/app/dateRanges';
import { ApplicationSliceTypes } from 'app/models/app/applicationSliceTypes';
import { AbbreviatedLookupDto, AppAnalyticsDto, ApplicationDto, CdlClassDto, DriverTypeDto, HiringStateDto, LookupDto } from 'app/models/dtos';
import { ApplicationContactTimeLookup, BaseLookup, CdlClassesLookup, DriverTypesLookup, ExperienceTypesLookup, FreightTypesLookup, LeadTypesLookup } from 'app/models/lookups';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { ApexAxisChartSeries, ApexOptions, ApexXAxis } from 'ng-apexcharts';
import { ApplicationViewTypes } from 'app/models/app/applicationViewTypes';
import { HiringStatesLookup } from 'app/models/lookups/hiringStates.lookup';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { StackedChartAppSet } from 'app/models/app/charts/stackedChartAppSet';
import { DateSlices } from 'app/models/app/dateSlices';
import { saveAs } from 'file-saver';
import { CsvImportRow, CsvImportRowMappers } from 'app/models/integrations/csv/csvImportRow';
import { PermissionsService } from 'app/services/permissions/permissions.service';
import { AnySourceData, Map } from 'mapbox-gl';
import { TdusaMapElements } from '../../maps/tdusa-map-elements';
import { AppAnalyticDateContainer, AppAnalyticPair, AppAnalytics2Dto } from 'app/models/dtos/appAnalytics2.dto';
import { analytics } from 'app/mock-api/dashboards/analytics/data';
import { AnalyticsLocationDto } from 'app/models/dtos/analyticsLocation.dto';
import { LeadRadiusComponent } from './lead-radius/lead-radius.component';
import { MatDialog } from '@angular/material/dialog';
import { LeadRadius } from 'app/models/app/leadRadius';
import { AppAnalytics3Dto } from 'app/models/dtos/appAnalytics3.dto';
import { SelectStatesModalComponent, SelectStatesModalComponentInput } from '../../common/select-states-modal/select-states-modal.component';

@Component({
  selector: 'app-leads-analytics',
  templateUrl: './leads-analytics.component.html',
  styleUrls: ['./leads-analytics.component.css']
})
export class LeadsAnalyticsComponent implements OnInit {
  constructor(private http: HttpClient, public dialog: MatDialog) { }

  //inputs
  @Input() applicationRoute: string;
  @Input() permissionsService: PermissionsService;

  //view children
  @ViewChild('applicationsTable', { read: MatSort, static: false }) applicationsTableMatSort: MatSort;

  //vars
  applications: ApplicationDto[] = [];
  _filteredApplications: ApplicationDto[] = null;
  // appAnalytics: AppAnalyticsDto[] = [];
  appAnalytics3: AppAnalytics3Dto = new AppAnalytics3Dto();
  appAnalytics2: AppAnalyticDateContainer[] = [];
  locations: AnalyticsLocationDto[] = [];
  _filteredAppAnalytics: AppAnalyticsDto[] = null; //cache
  searchText: string = '';
  applicationsDataSource: TableVirtualScrollDataSource<ApplicationDto> = new TableVirtualScrollDataSource([]);
  customRange: any = {
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: moment().toDate()
  }
  applicationsTableColumns(): string[] {
    if (this.permissionsService?.isSuperAdmin && this.selectedViewType === this.appViewTypesRaw.SUSPISCIOUS_APPS.id) {
      return this.columns();
    }
    if (this.permissionsService?.canAdmin() ?? false) {
      return this.columns()
        .filter(c => c != 'suspicious');
    }
    else {
      return this.columns()
        .filter(c => c != 'suspicious'
          && c != 'utmSource'
          && c != 'utmMedium'
          && c != 'utmCampaign')
    }
  }

  private columns(): string[] {
    return [
      'suspicious',
      'id',
      'created',
      'firstName',
      'lastName',
      'email',
      'phone',
      'zip',
      'city',
      'state',
      'cdl',
      'experience',
      'accidents',
      'violations',
      'endorsements',
      'driverType',
      'interestOOorLP',
      'hasOwnAuthority',
      'teamDriver',
      'interestInTeamDriving',
      'freight',
      'interestedFreightTypes',
      'militaryExperience',
      'applicationContactTimeId',
      'ipAddress',
      'matchedCompanies',
      'utmSource',
      'utmMedium',
      'utmCampaign'
    ];
  }

  moment = moment;
  selectedViewType = ApplicationViewTypes.LEADS.id;
  selectedRange = DateRanges.THIS_WEEK.id;
  selectedHiringState = HiringStatesLookup.ALL.id;
  selectedHiringStates: HiringStatesLookup[] = [];
  selectedSliceType: ApplicationSliceTypes = ApplicationSliceTypes.SOURCE;
  overTimeSliced: boolean = false;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  exportViewState = ViewState.content;
  mapViewState = ViewState.loading;

  //type lists
  driverTypes = structuredClone(DriverTypesLookup.values);
  cdlClasses = structuredClone(CdlClassesLookup.values);
  experienceTypes = structuredClone(ExperienceTypesLookup.values);
  freightTypes = structuredClone(FreightTypesLookup.values);
  appViewTypes: ApplicationViewTypes[] = structuredClone(ApplicationViewTypes.values);
  dateRanges: DateRanges[] = structuredClone(DateRanges.values);
  hiringStates: HiringStatesLookup[] = structuredClone(HiringStatesLookup.values);

  //raw types
  appViewTypesRaw = ApplicationViewTypes;
  dateRangesRaw = DateRanges;
  experienceTypesRaw = ExperienceTypesLookup;
  freighTypesRaw = FreightTypesLookup;
  cdlClassesRaw = CdlClassesLookup;
  driverTypesRaw = DriverTypesLookup;
  applicationContactTimesRaw = ApplicationContactTimeLookup;

  //charts
  applicationSliceTypes: ApplicationSliceTypes[] = structuredClone(ApplicationSliceTypes.values);
  chartAppsOverTime: ApexOptions;
  chartUtmSources: ApexOptions;
  _slicePieData: PieChartDataPair[];
  _sliceChartData: ApexAxisChartSeries;

  //map
  map: Map;
  hasLoadedZipsSource: boolean = false;
  leadRadius: LeadRadius = null;

  ngOnInit() {
    //set view type selections
    this.appViewTypes = this.applicationRoute.includes('companies') ? structuredClone(ApplicationViewTypes.companyValues) : structuredClone(ApplicationViewTypes.values);
  }

  ngAfterViewInit() {
    this.getResults();
  }

  //api
  getResults() {
    this.getApplications();
    this.getApplicationAnalytics2();
  }
  getApplications(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/${this.applicationRoute}`, {
        params: this.applicationsQueryString()
      })
      .subscribe((result: ApplicationDto[]) => {
        this.applications = result;
        // for (let index = 0; index < 12; index++) {
        //   this.applications = this.applications.concat(this.applications);
        // }
        this.viewState = ViewState.content;
        this.invalidateStats();
      });
  }

  getApplicationAnalytics(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/${this.applicationRoute}-analytics`, {
        params: this.applicationsQueryString()
      })
      .subscribe((result: AppAnalyticDateContainer[]) => {
        this.appAnalytics2 = result;
        this.invalidateStats();
        this.invalidateCharts();
      });
  }

  getApplicationAnalytics2(): void {
    this.viewState = ViewState.loading;

    this.http
      .get(`${environment.services_tdusa_admin}/${this.applicationRoute}-analytics2`, {
        params: this.applicationsQueryString()
      })
      .subscribe((result: AppAnalytics3Dto) => {
        this.appAnalytics3 = result;
        this.invalidateStats();
        // this.invalidateCharts();
      });
  }

  getApplicationLocations(): void {
    //check for cache hit first
    if (this.locations.length > 0) {
      this.updateMapZips();
      return;
    }

    //otherwise fetch
    this.mapViewState = ViewState.loading;
    this.http
      .get(`${environment.services_tdusa_admin}/${this.applicationRoute}-locations`, {
        params: this.applicationsQueryString()
      })
      .subscribe((result: AnalyticsLocationDto[]) => {
        this.locations = result;

        //render locations
        this.updateMapZips();

        this.mapViewState = ViewState.content;
      });
  }

  applicationsQueryString(): any {
    var range = this.dateRanges.find(range => range.id == this.selectedRange);

    var params: any = {};
    //set date range and offset
    if (range.id == '7') {
      //custom
      params = {
        startDate: moment(this.customRange.startDate).startOf('date').toISOString(),
        endDate: moment(this.customRange.endDate).endOf('date').toISOString()
      };
    }
    else {
      params = range.params;
    }
    params.utcOffset = moment().utcOffset();
    params.limit = 20;

    //view type
    params.vt = this.selectedViewType;

    //state filter
    if(this.selectedHiringStates.length > 0) {
      params.state = this.selectedHiringStates.filter(t => t.checked).map(t => t.id);
    }
    else {
      delete params.state;
    }

    //radius filter
    if (this.leadRadius != null) {
      params.radius = this.leadRadius.radius;
      params.lat = this.leadRadius.lngLat.lat;
      params.lng = this.leadRadius.lngLat.lng;
    } else {
      delete params.radius;
      delete params.lat;
      delete params.lng;
    }

    //query filter
    params.q = this.searchText.trim();

    //type filters
    if (this.driverTypes.filter(t => t.checked).length > 0) {
      params.dt = this.driverTypes.filter(t => t.checked).map(t => t.id);
    } else { delete params.dt; }
    if (this.cdlClasses.filter(t => t.checked).length > 0) {
      params.lic = this.cdlClasses.filter(t => t.checked).map(t => t.id);
    } else { delete params.lic; }
    if (this.experienceTypes.filter(t => t.checked).length > 0) {
      params.exp = this.experienceTypes.filter(t => t.checked).map(t => t.id);
    } else { delete params.exp; }
    if (this.freightTypes.filter(t => t.checked).length > 0) {
      params.ft = this.freightTypes.filter(t => t.checked).map(t => t.id);
    } else { delete params.ft; }

    return params;
  }

  overrideSuspiciousApp(application: ApplicationDto): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/applications/${application.id}/suspicious/override`, null)
      .subscribe(() => {
        this.applications = this.applications.filter(a => a.id != application.id);
        this.viewState = ViewState.content;
        this.invalidateStats();
      });
  }

  blockSuspiciousApp(application: ApplicationDto): void {
    this.viewState = ViewState.loading;

    this.http
      .put(`${environment.services_tdusa_admin}/v1/applications/${application.id}/suspicious/block`, null)
      .subscribe(() => {
        this.applications = this.applications.filter(a => a.id != application.id);
        this.viewState = ViewState.content;
        this.invalidateStats();
      });
  }

  uniqueifyApps(apps: ApplicationDto[]): ApplicationDto[] {
    switch (this.selectedViewType) {
      case ApplicationViewTypes.UNIQUE_APPS.id:
        var pairs = {};
        apps.forEach(a => {
          const key = a.email.toLowerCase();
          if (pairs[key] == null) {
            pairs[key] = a
          }
        })
        return Object.values(pairs);
      default:
        return apps;
    }
  }

  seachTextDidChange(text: string) {
    if (text.length > 0 && text.length < 3) { return; }
    this.getResults();
  }

  updateSorting(applications: ApplicationDto[]) {
    this.applicationsDataSource = new TableVirtualScrollDataSource(applications);
    this.applicationsDataSource.sort = this.applicationsTableMatSort;
    this.applicationsDataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
      return item[property];
    };
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  serializedLookups(lookups: string[], types: any): string {
    return lookups.map(l => types.fromId(l).name).join(', ');
  }

  serializedAbbreviatedLookups(lookups: AbbreviatedLookupDto[]): string {
    return lookups.map(l => l.abbreviation).join(', ');
  }

  selectApplicationViewType(applicationViewTypeid: string) {
    this.selectedViewType = applicationViewTypeid;
    this.invalidateStats();
  }

  applicationViewTypeFromId(applicationViewTypeId: string): ApplicationViewTypes {
    return this.appViewTypes.find(t => t.id == applicationViewTypeId);
  }

  //stats/filters
  invalidateStats() {
    this._filteredAppAnalytics = null;
    this._filteredApplications = null;
    this.updateSorting(this.applications);
    this.invalidateCharts();
    if (this.map != null) {
      this.locations = [];
      this.getApplicationLocations();
      this.updateMapZips();
    }
  }
  appsTotal(): number {
    return this.appAnalytics3.total;

    return this.appAnalytics2
      .map(a => a.analytic.total)
      .reduce((partialSum, a) => partialSum + a, 0);
  }
  appsDirect(): number {
    return this.appAnalytics3.direct;

    return this.appAnalytics2
      .map(a => a.analytic.direct)
      .reduce((partialSum, a) => partialSum + a, 0);
  }
  appsMatched(): number {
    return this.appAnalytics3.matched;

    return this.appAnalytics2
      .map(a => a.analytic.matched)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  percentMaxSends(): number {
    return 0;
  }
  cdlHolders(): number {
    return this.appAnalytics3.cdlClasses
      .filter(a => a.id == CdlClassesLookup.CLASS_A.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);

    return this.appAnalytics2
      .flatMap(a => a.analytic.cdlClasses)
      .filter(a => a.id == CdlClassesLookup.CLASS_A.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }
  states(): number {
    var pairs = {};
    this.appAnalytics2.forEach(a => {
      a.analytic.states.forEach(s => {
        pairs[s.id] = 0;
      })
    })
    return Object.keys(pairs).length;
  }

  driverTypeTotal(type: DriverTypesLookup): number {
    return this.appAnalytics3.driverTypes
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);

    return this.appAnalytics2
      .flatMap(a => a.analytic.driverTypes)
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  cdlClassTotal(type: CdlClassesLookup): number {
    return this.appAnalytics3.cdlClasses
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);

    return this.appAnalytics2
      .flatMap(a => a.analytic.cdlClasses)
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  experienceTypeTotal(type: ExperienceTypesLookup): number {
    return this.appAnalytics3.experienceTypes
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);

    return this.appAnalytics2
      .flatMap(a => a.analytic.experienceTypes)
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  freightTypeTotal(type: DriverTypeDto): number {
    return this.appAnalytics3.freightTypes
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);

    return this.appAnalytics2
      .flatMap(a => a.analytic.freightTypes)
      .filter(a => a.id == type.id)
      .map(a => a.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  appsWithViolations(): number {
    return this.appAnalytics3.violations;

    return this.appAnalytics2
      .map(a => a.analytic.violations)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  appsWithAccidents(): number {
    return this.appAnalytics3.accidents;

    return this.appAnalytics2
      .map(a => a.analytic.accidents)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  appsWithMilitaryExp(): number {
    return this.appAnalytics3.military;

    return this.appAnalytics2
      .map(a => a.analytic.military)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  interestInLP(): number {
    return this.appAnalytics3.interestInOoLp;

    return this.appAnalytics2
      .map(a => a.analytic.interestInOoLp)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  interestInTeams(): number {
    return this.appAnalytics3.interestInTeams;

    return this.appAnalytics2
      .map(a => a.analytic.interestInTeams)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  //chart
  invalidateCharts() {
    //invalidate local caches
    this._slicePieData = null;
    this._sliceChartData = null;

    //rebuild charts
    this.buildCharts();
  }

  buildCharts() {
    this.chartAppsOverTime = {
      series: this.overTimeSliced ? this.slicedChartSeries(this.selectedSliceType) : this.chartSeries(this.selectedSliceType),
      chart: {
        type: 'line',
        height: 400,
        width: 400,
        stacked: false,
        toolbar: {
          show: false
        }
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          },
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        },
      },
      xaxis: this.chartXAxis(),
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    };

    // this.chartUtmSources = {
    //   series: this.sliceChartData(this.selectedSliceType).map(t => t.value),
    //   chart: {
    //     width: '100%',
    //     height: 400,
    //     type: 'donut',
    //   },
    //   labels: this.sliceChartData(this.selectedSliceType).map(t => t.label),
    //   legend: {
    //     position: 'bottom',
    //     offsetY: 40,
    //     show: false
    //   }
    // };
    // const asdf = this.sliceChartData(this.selectedSliceType);
  }

  selectSliceType(type: ApplicationSliceTypes) {
    this.selectedSliceType = type;
    this.invalidateCharts();
  }

  sliceChartData(type: ApplicationSliceTypes): PieChartDataPair[] {
    //check cache
    if (this._slicePieData != null) { return this._slicePieData; }

    //get solo types
    if (type.id == ApplicationSliceTypes.SOURCE.id
      || type.id == ApplicationSliceTypes.MEDIUM.id
      || type.id == ApplicationSliceTypes.CAMPAIGN.id) {
      this._slicePieData = this.flatSlices(ApplicationSliceTypes.fromId(type.id).propName);
    }
    else if (
      type.id == ApplicationSliceTypes.STATE.id
      || type.id == ApplicationSliceTypes.DRIVER_TYPE.id
      || type.id == ApplicationSliceTypes.CDL_CLASSES.id
      || type.id == ApplicationSliceTypes.EXPERIENCE_TYPE.id
      || type.id == ApplicationSliceTypes.FREIGHT_TYPE.id) {
      this._slicePieData = this.typedSlices(ApplicationSliceTypes.fromId(type.id));
    }

    //clean up nulls
    this._slicePieData.forEach(s => {
      if (s.label == 'null') { s.label = 'N/A'; }
    })

    return this._slicePieData;
  }

  flatSlices(key: string): PieChartDataPair[] {
    const pairMap = this.appAnalytics2.flatMap(a => a.analytic[key]).reduce((acc, curr) => {
      if (!acc[curr.id]) acc[curr.id] = curr.amount; //If this type wasn't previously stored
      else { acc[curr.id] += curr.amount; }
      return acc;
    }, {});
    return Object.entries(pairMap).map(e => new PieChartDataPair(e[0], e[1] as number));
  }

  typedSlices(type: ApplicationSliceTypes): PieChartDataPair[] {
    const key = ApplicationSliceTypes.fromId(type.id).propName;

    const pairMap = this.appAnalytics2.flatMap(a => a.analytic[key]).reduce((acc, curr) => {
      if (!acc[curr.id]) acc[curr.id] = curr.amount; //If this type wasn't previously stored
      else { acc[curr.id] += curr.amount; }
      return acc;
    }, {});
    return Object.entries(pairMap).map(e => {
      if (type.id == ApplicationSliceTypes.STATE.id) {
        if (HiringStatesLookup.fromId(e[0]) == null) { return new PieChartDataPair('N/A', 0); } //backward compat. check. Needed because we restricted hiring states after release
        return new PieChartDataPair(HiringStatesLookup.fromId(e[0]).abbreviation, e[1] as number);
      }
      else if (type.id == ApplicationSliceTypes.DRIVER_TYPE.id) {
        return new PieChartDataPair(DriverTypesLookup.fromId(e[0]).name, e[1] as number);
      }
      else if (type.id == ApplicationSliceTypes.CDL_CLASSES.id) {
        return new PieChartDataPair(CdlClassesLookup.fromId(e[0]).name, e[1] as number);
      }
      else if (type.id == ApplicationSliceTypes.EXPERIENCE_TYPE.id) {
        return new PieChartDataPair(ExperienceTypesLookup.fromId(e[0]).name, e[1] as number);
      }
      else if (type.id == ApplicationSliceTypes.FREIGHT_TYPE.id) {
        return new PieChartDataPair(FreightTypesLookup.fromId(e[0]).name, e[1] as number);
      }
    });
  }

  viewTypeValue(application: AppAnalyticsDto): number {
    switch (this.selectedViewType) {
      case ApplicationViewTypes.LEADS.id:
        return application.l;
      // case ApplicationViewTypes.MATCHES.id:
      //   return application.m;
      default:
        return 1;
    }
  }

  chartSeries(type: ApplicationSliceTypes): ApexAxisChartSeries {
    //check cache
    if (this._sliceChartData != null) { return this._sliceChartData; }

    //flattened total
    const totalSet = new StackedChartAppSet('Total', this.appAnalytics2);

    //build range
    const buckets = this.appAnalytics2
      .map(a => a.analytic.total);

    const chartSeries: ApexAxisChartSeries = [{
      name: totalSet.label,
      data: buckets,

    }];
    this._sliceChartData = chartSeries;
    return chartSeries;
  }

  slicedChartSeries(type: ApplicationSliceTypes): ApexAxisChartSeries {

    var appSets2 = {};
    const propName = ApplicationSliceTypes.fromId(type.id).propName;
    //collect all keys for the slyce type
    this.appAnalytics2.forEach(analytics => {
      (analytics.analytic[propName] as AppAnalyticPair[]).forEach(p => appSets2[p.id] = p.amount);
    });

    //iterate over all keys and form buckets (i.e. series) for each key
    const chartSeries: ApexAxisChartSeries = Object.keys(appSets2).map(key => {
      var seriesName = key; //defaults to solo
      const buckets = this.appAnalytics2.map(analytics => {
        return (analytics.analytic[propName] as AppAnalyticPair[]).find(p => p.id == key)?.amount ?? 0;
      });

      //generate series name from type-based id
      if (type.id == ApplicationSliceTypes.STATE.id) {
        if (HiringStatesLookup.fromId(key) != null) {
          seriesName = HiringStatesLookup.fromId(key).abbreviation;
        } //backward compat. check. Needed because we restricted hiring states after release
        else {
          seriesName = 'N/A';
        }
      }
      else if (type.id == ApplicationSliceTypes.DRIVER_TYPE.id) {
        seriesName = DriverTypesLookup.fromId(key).name;
      }
      else if (type.id == ApplicationSliceTypes.CDL_CLASSES.id) {
        seriesName = CdlClassesLookup.fromId(key).name;
      }
      else if (type.id == ApplicationSliceTypes.EXPERIENCE_TYPE.id) {
        seriesName = ExperienceTypesLookup.fromId(key).name;
      }
      else if (type.id == ApplicationSliceTypes.FREIGHT_TYPE.id) {
        seriesName = FreightTypesLookup.fromId(key).name;
      }

      return {
        name: seriesName,
        data: buckets,
      }
    });

    this._sliceChartData = chartSeries;
    return chartSeries;
  }

  chartXAxis(): ApexXAxis {
    const ticks = this.appAnalytics2.map(a => `${a.anchor}`);
    return {
      categories: ticks,
      tickPlacement: 'on'
    }
  }

  //Export
  downloadLeadsCsv() {
    this.exportViewState = ViewState.loading;

    //get all that would go into csv
    const params = this.applicationsQueryString();
    params.limit = 999999;

    this.http
      .get(`${environment.services_tdusa_admin}/${this.applicationRoute}`, {
        params: params
      })
      .subscribe((result: ApplicationDto[]) => {
        const csvApplications = this.uniqueifyApps(result);

        //make a csv and download
        try {
          var blob = new Blob([this.csvFromApplications(csvApplications)], { type: 'text/csv' })
          saveAs(blob, "report.csv");
        } catch (error) {

        }

        this.exportViewState = ViewState.content;
      });
  }

  csvFromApplications(applications: ApplicationDto[]): string {

    var rows = applications.map(a => CsvImportRowMappers.fromApplication(a));
    var fields = Object.keys(rows[0] ?? new CsvImportRow());

    var replacer = function (key, value) { return value === null ? '' : value }
    var csv = rows.map(row => {
      return fields.map(fieldName => {
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    })
    csv.unshift(fields.join(',')) // add header column
    const csvString = csv.join('\r\n');
    // console.log(csv)
    return csvString
  }

  //map
  onMapLoad(event) {
    this.map = event;

    //get locations
    this.getApplicationLocations();
  }

  updateMapZips() {
    //remove old source
    if (this.hasLoadedZipsSource) {
      this.map.removeLayer(TdusaMapElements.zipsLayerTag);
      this.map.removeSource(TdusaMapElements.zipsSourceTag);
    }

    this.map.addSource(TdusaMapElements.zipsSourceTag, this.zipsMapSource(this.locations.filter(p => p.lat != null && p.lng != null)));
    this.map.addLayer(TdusaMapElements.reportZipsLayer());

    this.hasLoadedZipsSource = true;
  }

  zipsMapSource(positions: AnalyticsLocationDto[]): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: positions.map(z => TdusaMapElements.circleMarkerForLatLng(z.lat, z.lng, 15))
      }
    }
  }

  tabClick(tabEvent: any) {
    if (tabEvent.tab.textLabel === 'Charts') {
      this.hasLoadedZipsSource = false;
      this.map = null
    }
  }

  customRangeDatePickerDidChange() {
    if (this.customRange.startDate == null) { return; }
    if (this.customRange.endDate == null) { return; }

    this.getResults();
  }

  permissionSensitiveAppViewTypes(): ApplicationViewTypes[] {
    if (this.permissionsService.isSuperAdmin) {
      return this.appViewTypes;
    }
    else {
      return this.appViewTypes.filter(t => t.id !== this.appViewTypesRaw.SUSPISCIOUS_APPS.id);
    }
  }

  sum(numbers: PieChartDataPair[]): number {
    if (numbers == null) { return 0; }

    return numbers.reduce((partialSum, a) => partialSum + a.value, 0);
  }
  rankedSliceData(data: PieChartDataPair[]): PieChartDataPair[] {
    if (data == null) { return []; }
    if (data.length == 0) { return []; }

    return data.sort((a: PieChartDataPair, b: PieChartDataPair) => {
      if (a.value > b.value) { return -1; }
      else if (a.value < b.value) { return 1; }
      else { return 0; }
    });
  }

  //radius
  didClickSetRadius() {
    const dialogRef = this.dialog.open(LeadRadiusComponent, {
      data: this.leadRadius,
      maxHeight: '100%',
      height: '768px',
      width: '768px',
      minWidth: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      const radius: LeadRadius = result.data;
      switch (result.action) {
        case 'cancel':
          this.didClickClearRadius();

          break;
        case 'remove':
          this.didClickClearRadius();
          break;
        case 'confirm':
          this.didClickConfirmRadius(radius);
          //search with radius
          break;
        default:
          break;
      }
      this.invalidateStats();
    });
  }

  didClickClearRadius() {
    this.leadRadius = null;
    this.getResults();
  }

  didClickConfirmRadius(radius: LeadRadius) {
    this.leadRadius = radius;
    this.getResults();
  }

  showSelectStates() {
    //build input
    const input = new SelectStatesModalComponentInput();
    input.states = this.selectedHiringStates;

    //show modal
    const dialogRef = this.dialog.open(SelectStatesModalComponent, {
      data: input,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == null) { return; }

      const states: HiringStatesLookup[] = result;
      this.selectedHiringStates = states;

      this.getResults();
    });
  }
}