<!--loading-->
<div *ngIf="viewState == viewStates.loading">
    <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<!--content-->
<div
    *ngIf="viewState == viewStates.content"
    class="flex flex-col gap-3"
    style="min-width: 480px"
>
    <div class="flex flex-col">
        <div class="text-2xl font-bold">Create Matched Leads Product</div>
        <div class="flex flex-col md:flex-row">
            <div class="flex flex-row gap-1 items-center grow">
                <img
                    class="w-4 h-4"
                    src="{{ jobPosting.jobPostingType.imageUrl }}"
                />
                <div class="text-lg">
                    {{ jobPosting.jobPostingType.name }} Job Posting #{{
                        jobPosting.referenceId
                    }}
                </div>
            </div>
            <div class="flex flex-row gap-3">
                <button
                mat-stroked-button
                color="primary"
                class="w-full md:w-auto"
                [swal]="{ title: 'Confirm creation of new company?', confirmButtonText: 'Create', icon: 'info', confirmButtonColor: '#28a745', showCancelButton: true }"
                  (confirm)="createCompany()"
            >
                <span class="text-sm font-bold">New company</span>
            </button>

                <button
                    mat-flat-button
                    color="primary"
                    class="w-full md:w-auto"
                    (click)="didClickDismiss()"
                >
                    Dismiss
                </button>
                <!--creation-->
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>

    <div class="flex flex-row gap-3">
        <mat-form-field class="grow">
            <img
                *ngIf="selectedCompany()"
                matPrefix
                class="w-4 h-4 object-cover rounded"
                [src]="selectedCompany()?.imageUrl"
            />
            <mat-select [(value)]="selectedCompanyId">
                <mat-option
                    *ngFor="let company of companies"
                    [value]="company.id"
                >
                    <div class="flex flex-row gap-2 items-center">
                        <img
                            class="w-4 h-4 object-cover rounded"
                            [src]="company?.imageUrl"
                        />
                        <div>{{ company?.name }}</div>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button
            mat-stroked-button
            color="primary"
            class="w-full md:w-auto"
            [disabled]="selectedCompany() == null"
            [swal]="{ title: 'Add product to selected company?', confirmButtonText: 'Create', icon: 'info', confirmButtonColor: '#28a745', showCancelButton: true }"
              (confirm)="createProduct()"
        >
            <span class="text-sm font-bold">Add to company</span>
        </button>
    </div>

    <div class="flex flex-row gap-3">
        <mat-form-field class="grow">
            <img
                *ngIf="selectedProduct()"
                matPrefix
                class="w-4 h-4 object-cover rounded"
                [src]="selectedProduct().company?.imageUrl"
            />
            <mat-select [(value)]="selectedProductId">
                <mat-option
                    *ngFor="let product of products"
                    [value]="product.id"
                >
                    <div class="flex flex-row gap-2 items-center">
                        <img
                            class="w-4 h-4 object-cover rounded"
                            [src]="product.company.imageUrl"
                        />
                        <div>{{ product.name }} ({{product.productStatus?.productStatusType?.name}})</div>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button
            mat-stroked-button
            color="primary"
            class="w-full md:w-auto"
            [disabled]="selectedProduct() == null"
            [swal]="{ title: 'Add job to selected product?', confirmButtonText: 'Create', icon: 'info', confirmButtonColor: '#28a745', showCancelButton: true }"
              (confirm)="addToProduct()"
        >
            <span class="text-sm font-bold">Add to product</span>
        </button>
    </div>

    <mat-divider></mat-divider>
    <!--content-->
    <div
        class="flex flex-col gap-3 overflow-scroll"
        style="max-height: calc(100vh - 300px)"
    >
        <div class="text-lg font-bold">Company & Matched Leads Product</div>
        <div class="flex flex-row gap-2">
            <div class="font-bold">Name</div>
            <div>{{ mlp().name }}</div>
        </div>
        <div *ngIf="mlp().currentSpendPeriod">
            <div class="font-bold">Product Spend Period</div>
            <div class="flex flex-row flex-wrap gap-8">
                <div class="flex flex-row gap-2">
                    <div class="font-bold">Start</div>
                    <div>
                        {{
                            moment(
                                mlp().currentSpendPeriod.timestampStart
                            ).format("LL")
                        }}
                    </div>
                </div>
                <div class="flex flex-row gap-2">
                    <div class="font-bold">End</div>
                    <div>
                        {{
                            moment(
                                mlp().currentSpendPeriod.timestampEnd
                            ).format("LL")
                        }}
                    </div>
                </div>
                <div class="flex flex-row gap-2">
                    <div class="font-bold">Spend</div>
                    <div>{{ mlp().currentSpendPeriod.spend }}</div>
                </div>
                <div class="flex flex-row gap-2">
                    <div class="font-bold">Allocation %</div>
                    <div>
                        {{ mlp().currentSpendPeriod.allocationPercentage }}
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>
        <div class="text-lg font-bold">Job</div>

        <app-job-details-card
            [job]="job()"
            [showMap]="false"
        ></app-job-details-card>
    </div>
</div>
