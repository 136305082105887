import { BaseLookup } from "./base.lookup";

export class ExternalJobFeedTypesLookup extends BaseLookup {
  
    // Values
    static readonly TDUSA_XML = new ExternalJobFeedTypesLookup('1', 'TDUSA XML');
    static readonly TDUSA_XML_LEGACY = new ExternalJobFeedTypesLookup('2', 'TDUSA XML (Legacy)');
    static readonly APPCAST = new ExternalJobFeedTypesLookup('3', 'Appcast');
    static readonly ACQUIRE = new ExternalJobFeedTypesLookup('4', 'Acquire');
  
    static readonly values = [
      ExternalJobFeedTypesLookup.TDUSA_XML,
      ExternalJobFeedTypesLookup.TDUSA_XML_LEGACY,
      ExternalJobFeedTypesLookup.APPCAST,
      ExternalJobFeedTypesLookup.ACQUIRE,
    ]
  }