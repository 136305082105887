import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ViewState } from 'app/models/app';
import { ApplicationDto } from 'app/models/dtos';
import { AccidentsLookup, ApplicationContactTimeLookup, CdlClassesLookup, DriverTypesLookup, EndorsementTypesLookup, ExperienceTypesLookup, FreightTypesLookup, SoloTeamLookup, ViolationsLookup } from 'app/models/lookups';
import { HiringStatesLookup } from 'app/models/lookups/hiringStates.lookup';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-lead-application',
  templateUrl: './lead-application.component.html',
  // styleUrls: ['./lead-application.component.css']
})
export class LeadApplicationComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private _formBuilder: UntypedFormBuilder) { }

  //inputs
  @Input() application: ApplicationDto;

  //vars
  formLocked: boolean = false;

  //view states
  viewStates = ViewState;
  viewState = ViewState.initial;

  //values
  cdlClasses = structuredClone(CdlClassesLookup.values);
  experience = structuredClone(ExperienceTypesLookup.values);
  endorsements = structuredClone(EndorsementTypesLookup.values);
  violations = structuredClone(ViolationsLookup.values);
  accidents = structuredClone(AccidentsLookup.values);
  driverTypes = structuredClone(DriverTypesLookup.values);
  soloTeam = structuredClone(SoloTeamLookup.values);
  commonFreight = structuredClone(FreightTypesLookup.values);
  interestedFreight = structuredClone(FreightTypesLookup.values);
  contactTimes = structuredClone(ApplicationContactTimeLookup.values);
  terms = false;
  
  // cdlGroup = this._formBuilder.group({});

  ngOnInit() {
    this.setDefaultSelections();
  }

  setDefaultSelections() {
    this.cdlClasses.forEach(c => c.checked = this.application.cdlClasses.find(c2 => c2 == c.id) != null);
    this.endorsements.forEach(c => c.checked = this.application.endorsements.find(c2 => c2 == c.id) != null);
    this.interestedFreight.forEach(c => c.checked = this.application.interestedFreightTypes.find(c2 => c2 == c.id) != null);

    this.violations.forEach(v => v.checked = v.id == `${this.application.violations}`);
    this.accidents.forEach(v => v.checked = v.id == `${this.application.accidents}`);

    this.commonFreight.find(i => i.id == this.application.freightType).checked = true;
    this.driverTypes.find(i => i.id == this.application.driverType).checked = true;
    this.experience.find(i => i.id == this.application.experienceType).checked = true;
    this.contactTimes.find(i => i.id == this.application.applicationContactTime).checked = true;

    if (this.application.teamDriver) {
      this.soloTeam.find(i => i.id == SoloTeamLookup.TEAM.id).checked = true;
    }
    else {
      this.soloTeam.find(i => i.id == SoloTeamLookup.SOLO.id).checked = true;
    }
    
  }

  //state visibility
  shouldShowEndorsements(): Boolean {
    return this.application.cdlClasses.find(c => c == '1') != null
  }

  shouldShowInterestInOO(): Boolean {
    return this.application.driverType == DriverTypesLookup.COMPANY_DRIVER.id;
  }

  shouldShowOwnAuthority(): Boolean {
    return this.application.driverType == DriverTypesLookup.OWNER_OPERATOR.id;
  }

  shouldShowInterestInTeamDriving(): Boolean {
    if (this.application.teamDriver == null) { return false; }
    return !this.application.teamDriver;
  }
}
