<!--loading-->
<div *ngIf="viewState == viewStates.loading">
  <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
</div>

<div *ngIf="viewState == viewStates.content" class="grid grid-cols-12 gap-3">
  <!--status-->
  <fuse-card class="col-span-12 p-4 grid grid-cols-12 gap-8">
    <div class="col-span-12 {{permissionsService.canManage() ? 'md:col-span-6' : ''}}  text-center">
      <div><small><b>Current Status</b></small></div>
      <div class="mt-1">
        <div *ngIf="product.productStatus == null" class="text-slate-500">
          <mat-icon [svgIcon]="'account_tree'" class="text-slate-500 mr-2" style="vertical-align: top;">
          </mat-icon>
          <span class="font-bold text-lg">Status Not Set</span>
        </div>
        <div *ngIf="product.productStatus != null">
          <div>
            <mat-icon [svgIcon]="rawStatusTypes.valueForId(product.productStatus.productStatusType.id).matIcon"
              class="w-1 h-1 {{rawStatusTypes.valueForId(product.productStatus.productStatusType.id).textColor}} mr-2"
              style="vertical-align: top;"></mat-icon>
            <span
              class="font-bold text-lg {{rawStatusTypes.valueForId(product.productStatus.productStatusType.id).textColor}}">
              <span>{{product.productStatus.productStatusType.name}}</span>
              <span class="ml-2"
                *ngIf="product.productStatus?.productStatusType?.id === rawStatusTypes.PROSPECT.id">({{product.productStatus.prospectReason?.name}})</span>
              <span class="ml-2"
                *ngIf="product.productStatus?.productStatusType?.id === rawStatusTypes.SUSPENDED.id">({{product.productStatus.suspensionReason?.name}})</span>
            </span>
          </div>
        </div>
        <div *ngIf="product.productStatus != null">
          <small>{{moment(product.productStatus.timestampCreated).fromNow()}}</small></div>
      </div>

      <a *ngIf="permissionsService.canManage()"
        routerLink="/companies/{{company.id}}/matchedLeadsProducts/{{product.id}}/status" mat-flat-button
        color="primary" class="w-full mt-8">
        <mat-icon svgIcon="change_circle" class="mr-2"></mat-icon>
        <span>Update Product Status</span>
      </a>
    </div>
    <div class="col-span-12 md:col-span-6" *ngIf="permissionsService.canManage()">
      <!--target CPL-->
      <mat-form-field class="w-full">
        <mat-label><small><b>Target CPSL</b></small></mat-label>
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'attach_money'"></mat-icon>
        <input matInput type="number" min="0" step="0.01" [(ngModel)]="product.targetCpl"
          (ngModelChange)="targetCplDidChange()" [autocomplete]="'off'" placeholder="Period Budget">
      </mat-form-field>

      <mat-form-field class="w-full mt-4">
        <mat-label matTooltip="This number adjusts the number of days to pull historical matches from for this product. If left undefined, this number will default to a 30 day lead send window.">
          <small><b>Lead Window Override (default 30 days)</b></small>
        </mat-label>
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'calendar_today'"></mat-icon>
        <input type="number" min="0" max="30" matInput [(ngModel)]="product.leadWindowOverride" [autocomplete]="'off'" placeholder="e.g. 10">
      </mat-form-field>

      <button mat-flat-button color="primary" class="w-full mt-3" (click)="editProduct()">
        <mat-icon svgIcon="location_searching" class="mr-2"></mat-icon>
        <span>Update Product</span>
      </button>
    </div>

    <!-- <div>Status: {{product.productStatus?.productStatusType?.name ?? 'Unknown' }}</div>
    <div *ngIf="product.productStatus != null"><small>Updated
        {{moment(product.productStatus.timestampUpdated).fromNow()}}</small></div> -->
  </fuse-card>

  <!--current period-->
  <div class="mb-4 mt-4 text-center col-span-12" *ngIf="permissionsService.canManage()">
    <div class="text-2xl"><b>Period Management</b></div>
    <div>
      <small>View and manage the performance of the current period and prepare for future periods below.</small>
    </div>
  </div>
  <div class="col-span-12 md:col-span-6" *ngIf="permissionsService.canManage()">
    <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8 mb-4">
      <div class="text-small text-center text-secondary mb-4">
        <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:calendar'">
        </mat-icon>
        <b>Current Period</b>
      </div>

      <!--loading-->
      <div *ngIf="currentPeriodViewState == viewStates.loading">
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
      </div>

      <!--new-->
      <div *ngIf="currentPeriodViewState == viewStates.content && product.currentSpendPeriod == null" class="py-8">
        <button class="w-full" mat-flat-button [color]="'primary'" (click)="createCurrentPeriod()">
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Create</span>
        </button>
      </div>

      <!--existing-->
      <div *ngIf="currentPeriodViewState == viewStates.content && product.currentSpendPeriod != null"
        class="grid grid-cols-12 gap-3">

        <!--date range-->
        <div class="col-span-12">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label><small>Period Date Range*</small></mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date" [(ngModel)]="product.currentSpendPeriod.timestampStart">
              <input matEndDate placeholder="End date" [(ngModel)]="product.currentSpendPeriod.timestampEnd">
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <!--budget-->
        <div class="col-span-12 md:col-span-6">
          <mat-form-field class="w-full">
            <mat-label><small>Period Budget</small></mat-label>
            <mat-icon *ngIf="spendInCents == null" class="icon-size-5" matPrefix [svgIcon]="'attach_money'"></mat-icon>
            <!-- <mat-icon *ngIf="!budgetFormControl.hasError('required')" class="icon-size-5 text-emerald-500" matPrefix
              [svgIcon]="'attach_money'"></mat-icon> -->
            <input matInput type="number" min="0" step="0.01" [(ngModel)]="product.currentSpendPeriod.spend"
              (ngModelChange)="spendInCentsDidChange(product.currentSpendPeriod)" [autocomplete]="'off'"
              placeholder="Period Budget">
          </mat-form-field>
        </div>

        <!--allocation-->
        <div class="col-span-12 md:col-span-6">
          <mat-form-field class="w-full">
            <mat-label><small>Period Allocation</small></mat-label>
            <span class="ml-1 mr-2 text-secondary">%</span>
            <input matInput type="number" [(ngModel)]="product.currentSpendPeriod.allocationPercentage"
              (ngModelChange)="allocationDidChange(product.currentSpendPeriod)" min="0" max="100" step="1"
              [autocomplete]="'off'" placeholder="Period Allocation">
          </mat-form-field>
        </div>

        <!--free trial-->
        <div class="col-span-12 text-center font-bold">
          <ng-switchery onLabel="Free Trial" [(ngModel)]="product.currentSpendPeriod.freeTrial" name="future-free-trial"
            size="switchery-sm"
            matTooltip="Free trial mode will simulate the period budget to establish relative priority for sending leads. However, the actual budget will not be included for the sake of ad spend.">
          </ng-switchery>
        </div>

        <div class="col-span-12 md:col-span-6">
          <button class="w-full" mat-flat-button [color]="'warn'"
            [swal]="{ title: 'Suspend Period', confirmButtonText: 'Suspend', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
            (confirm)="suspendCurrentPeriod()">
            <!-- <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon> -->
            <span class="ml-2 mr-1">Cancel & Suspend</span>
          </button>
        </div>

        <div class="col-span-12 md:col-span-6">
          <button class="w-full" mat-flat-button [color]="'primary'" (click)="editCurrentPeriod()"
            [disabled]="!periodIsValid(product.currentSpendPeriod)">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button>
        </div>


        <div class="col-span-6 text-center">
          <small>Current Allocation Spend {{currentAllocationSpend(product.currentSpendPeriod) |
            currency:'USD'}}</small>
        </div>
        <div class="col-span-6 text-center">
          <small>Current Remaining Spend {{currentRemainingSpend(product.currentSpendPeriod) | currency:'USD'}}</small>
        </div>
      </div>

    </fuse-card>
  </div>

  <!--future period-->
  <div class="col-span-12 md:col-span-6" *ngIf="permissionsService.canManage()">
    <fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8 mb-4">
      <div class="text-small text-center text-secondary mb-4">
        <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:calendar'">
        </mat-icon>
        <b>Future Period</b>
      </div>

      <!--loading-->
      <div *ngIf="futurePeriodViewState == viewStates.loading">
        <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
      </div>

      <!--new-->
      <div *ngIf="futurePeriodViewState == viewStates.content && product.futureSpendPeriod == null" class="py-8">
        <button class="w-full" mat-flat-button [color]="'primary'" (click)="createFuturePeriod()">
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Create</span>
        </button>
      </div>

      <!--existing-->
      <div *ngIf="futurePeriodViewState == viewStates.content && product.futureSpendPeriod != null"
        class="grid grid-cols-12 gap-3">

        <!--date range-->
        <div class="col-span-12">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label><small>Period Date Range*</small></mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date" [(ngModel)]="product.futureSpendPeriod.timestampStart">
              <input matEndDate placeholder="End date" [(ngModel)]="product.futureSpendPeriod.timestampEnd">
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <!--budget-->
        <div class="col-span-12 md:col-span-6">
          <mat-form-field class="w-full">
            <mat-label><small>Period Budget</small></mat-label>
            <mat-icon *ngIf="spendInCents == null" class="icon-size-5" matPrefix [svgIcon]="'attach_money'"></mat-icon>
            <!-- <mat-icon *ngIf="!budgetFormControl.hasError('required')" class="icon-size-5 text-emerald-500" matPrefix
              [svgIcon]="'attach_money'"></mat-icon> -->
            <input matInput type="number" min="0" step="0.01" [(ngModel)]="product.futureSpendPeriod.spend"
              (ngModelChange)="spendInCentsDidChange(product.futureSpendPeriod)" [autocomplete]="'off'"
              placeholder="Period Budget">
          </mat-form-field>
        </div>

        <!--allocation-->
        <div class="col-span-12 md:col-span-6">
          <mat-form-field class="w-full">
            <mat-label><small>Period Allocation</small></mat-label>
            <span class="ml-1 mr-2 text-secondary">%</span>
            <input matInput type="number" [(ngModel)]="product.futureSpendPeriod.allocationPercentage"
              (ngModelChange)="allocationDidChange(product.futureSpendPeriod)" min="0" max="100" step="1"
              [autocomplete]="'off'" placeholder="Period Allocation">
          </mat-form-field>
        </div>

        <!--free trial-->
        <div class="col-span-12 text-center font-bold">
          <ng-switchery onLabel="Free Trial" [(ngModel)]="product.futureSpendPeriod.freeTrial" name="future-free-trial"
            size="switchery-sm"
            matTooltip="Free trial mode will simulate the period budget to establish relative priority for sending leads. However, the actual budget will not be included for the sake of ad spend.">
          </ng-switchery>
        </div>

        <div class="col-span-12 md:col-span-6">
          <button class="w-full" mat-flat-button [color]="'warn'"
            [swal]="{ title: 'Suspend Period', confirmButtonText: 'Suspend', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
            (confirm)="suspendFuturePeriod()">
            <!-- <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon> -->
            <span class="ml-2 mr-1">Cancel & Suspend</span>
          </button>
        </div>

        <div class="col-span-12 md:col-span-6">
          <button class="w-full" mat-flat-button [color]="'primary'" (click)="editFuturePeriod()"
            [disabled]="!periodIsValid(product.futureSpendPeriod)">
            <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span class="ml-2 mr-1">Save</span>
          </button>
        </div>


        <div class="col-span-6 text-center">
          <small>Current Allocation Spend {{currentAllocationSpend(product.futureSpendPeriod) | currency:'USD'}}</small>
        </div>
        <div class="col-span-6 text-center">
          <small>Current Remaining Spend {{currentRemainingSpend(product.futureSpendPeriod) | currency:'USD'}}</small>
        </div>
      </div>

    </fuse-card>
  </div>

  <!--stats-->
  <div class="col-span-12 grid grid-cols-12 gap-3">
    <div class="col-span-12">
      <div class="text-2xl font-bold">Spend Stats</div>
    </div>
    <fuse-card class="col-span-2 p-4 flex flex-col text-center">
      <div class="text-md text-primary">Period Spend</div>
      <div class="text-sm font-bold">
        <span *ngIf="product.currentSpendPeriod == null">N/A</span>
        <span *ngIf="product.currentSpendPeriod != null">{{product.currentSpendPeriod.spend | currency:'USD'}}</span>
      </div>
    </fuse-card>
    <fuse-card class="col-span-2 p-4 flex flex-col text-center" *ngIf="permissionsService.canManage()">
      <div class="text-md text-primary">Allocation Percentage</div>
      <div class="text-sm font-bold">
        <span *ngIf="product.currentSpendPeriod == null">N/A</span>
        <span *ngIf="product.currentSpendPeriod != null">{{product.currentSpendPeriod.allocationPercentage}}%</span>
      </div>
    </fuse-card>
    <fuse-card class="col-span-2 p-4 flex flex-col text-center" *ngIf="permissionsService.canManage()">
      <div class="text-md text-primary">Allocated $</div>
      <div class="text-sm font-bold">
        <span *ngIf="product.currentSpendPeriod == null">N/A</span>
        <span *ngIf="product.currentSpendPeriod != null">{{currentAllocationSpend(product.currentSpendPeriod) |
          currency:'USD'}}</span>
      </div>
    </fuse-card>
    <fuse-card class="col-span-2 p-4 flex flex-col text-center" *ngIf="permissionsService.canManage()">
      <div class="text-md text-primary">Amount Spent</div>
      <div class="text-sm font-bold">
        <span *ngIf="product.currentSpendPeriod == null">N/A</span>
        <span *ngIf="product.currentSpendPeriod != null">{{product.currentSpendPeriod.spentInCents/100 |
          currency:'USD'}}</span>
      </div>
    </fuse-card>
    <fuse-card class="col-span-2 p-4 flex flex-col text-center" *ngIf="permissionsService.canManage()">
      <div class="text-md text-primary">Amount Remaining</div>
      <div class="text-sm font-bold">
        <span *ngIf="product.currentSpendPeriod == null">N/A</span>
        <span
          *ngIf="product.currentSpendPeriod != null">{{(product.currentSpendPeriod.spendInCents-product.currentSpendPeriod.spentInCents)/100
          | currency:'USD'}}</span>
      </div>
    </fuse-card>
    <fuse-card class="col-span-2 p-4 flex flex-col text-center" *ngIf="permissionsService.canManage()">
      <div class="text-md text-primary">Current Daily Spend</div>
      <div class="text-sm font-bold">
        <span *ngIf="product.currentSpendPeriod == null">N/A</span>
        <span *ngIf="product.currentSpendPeriod != null">$</span>
      </div>
    </fuse-card>
  </div>
  <div class="col-span-12 grid grid-cols-12 gap-3">
    <div class="col-span-12">
      <div class="text-2xl font-bold">Lead Stats</div>
    </div>
    <fuse-card class="col-span-3 p-4 flex flex-col text-center">
      <div class="text-md text-primary">Calls + Sent Leads</div>
      <div class="text-sm font-bold">
        <span *ngIf="product.currentSpendPeriod != null">{{callsPlusSentLeads()}}</span>
        <span *ngIf="product.currentSpendPeriod == null">N/A</span>
      </div>
    </fuse-card>
    <fuse-card class="col-span-3 p-4 flex flex-col text-center">
      <div class="text-md text-primary">Percentage Chosen / Matched</div>
      <div class="text-sm font-bold">
        <span *ngIf="product.currentSpendPeriod != null">{{percentChosenPerMatched()}}%</span>
        <span *ngIf="product.currentSpendPeriod == null">N/A</span>
      </div>
    </fuse-card>
    <fuse-card class="col-span-3 p-4 flex flex-col text-center">
      <div class="text-md text-primary">CPSL</div>
      <div class="text-sm font-bold">
        <span *ngIf="product.currentSpendPeriod != null">{{costPerSentLead() | currency:'USD'}}</span>
        <span *ngIf="product.currentSpendPeriod == null">N/A</span>
      </div>
    </fuse-card>
    <fuse-card class="col-span-3 p-4 flex flex-col text-center">
      <div class="text-md text-primary">Est. Leads Remaining</div>
      <div class="text-sm font-bold">
        <span *ngIf="product.currentSpendPeriod != null">{{estimatedLeadsRemaining()}}</span>
        <span *ngIf="product.currentSpendPeriod == null">N/A</span>
      </div>
    </fuse-card>
  </div>


  <!--jobs list-->
  <div class="col-span-12 grid grid-cols-12 mt-8">
    <div class="col-span-4">
      <div class="text-2xl font-bold">Allocations</div>
    </div>
  </div>

  <div class="col-span-12">
    <!--list-->
    <!--loading-->
    <div *ngIf="allocationsViewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="allocationsViewState == viewStates.content && jobs.length === 0" class="mt-3">
      <app-generic-no-content title="No Allocations Found"
        subtitle="No jobs were found please refresh or update your search criteria."
        imageUrl="https://img.icons8.com/color/256/000000/budget.png" class="ml-auto mr-auto mt-5">
      </app-generic-no-content>
    </div>

    <!-- content -->
    <div [hidden]="allocationsViewState != viewStates.content || jobs.length === 0" class="w-full mt-3">
      <fuse-card class="w-full p-4">
        <div class="w-full overflow-x-auto mx-6">
          <table class="w-full bg-transparent" mat-table matSort [dataSource]="jobsDataSource" [trackBy]="trackByFn"
            #jobsTable>

            <!-- Job -->
            <ng-container matColumnDef="job">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Job
              </th>
              <td mat-cell *matCellDef="let job">
                <span class="whitespace-nowrap">
                  <small class="font-bold">
                    {{job.title}}
                  </small>
                </span>
              </td>
            </ng-container>

            <!-- Current Allocation -->
            <ng-container matColumnDef="currentAllocation">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Current Allocation
              </th>
              <td mat-cell *matCellDef="let job">
                <span class="whitespace-nowrap">
                  <small>
                    <span>{{splitAllocation(this.product.currentSpendPeriod)}}</span>
                    <span *ngIf="this.product.currentSpendPeriod != null"
                      class="ml-2">({{splitAllocationPercentage()}}%)</span>
                  </small>
                </span>
              </td>
            </ng-container>

            <!-- Future Allocation -->
            <ng-container matColumnDef="futureAllocation">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Future Allocation
              </th>
              <td mat-cell *matCellDef="let job">
                <span class="whitespace-nowrap">
                  <small>
                    <span>{{splitAllocation(this.product.futureSpendPeriod)}}</span>
                    <span *ngIf="this.product.futureSpendPeriod != null"
                      class="ml-2">({{splitAllocationPercentage()}}%)</span>
                  </small>
                </span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="jobsTableColumns"></tr>
            <tr class="order-row h-16" mat-row *matRowDef="let row; columns: jobsTableColumns;"></tr>
            <!-- <tr
              class="h-16 border-0"
              mat-footer-row
              *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
          </table>
        </div>
      </fuse-card>
    </div>
  </div>
</div>