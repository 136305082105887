<div class="flex flex-col flex-auto min-w-0">

  <!-- Header -->
  <app-generic-breadcrumbs terminal="Job Board Postings"
    fallbackIcon="play_circle_filled"></app-generic-breadcrumbs>

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [(ngModel)]="searchText" [autocomplete]="'off'"
            (ngModelChange)="seachTextDidChange(searchText)" [placeholder]="'Filter job postings'">
        </mat-form-field>
      </div>

      <!-- Manage Sources button -->
      <a routerLink="/jobBoardSources" class="ml-4" mat-flat-button [color]="'primary'" *ngIf="permissionsService.canAdmin()">
        <mat-icon [svgIcon]="'mat_outline:edit'"></mat-icon>
        <span class="ml-2 mr-1">Manage Sources</span>
      </a>
    </div>


    <!--list-->
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading">
      <mat-spinner class="ml-auto mr-auto mt-5"></mat-spinner>
    </div>

    <!-- no content -->
    <div *ngIf="viewState == viewStates.content && filteredTenstreetJobPostings.length === 0" class="mt-8">
      <app-generic-no-content title="No Job Postings Found"
        subtitle="No job postings were found please refresh or update your search criteria."
        imageUrl="https://img.icons8.com/color/256/000000/permanent-job.png"
        class="ml-auto mr-auto mt-5"></app-generic-no-content>
    </div>

    <!-- content -->
    <div [hidden]="viewState != viewStates.content || filteredTenstreetJobPostings.length === 0" class="w-full mt-8">
      <fuse-card class="w-full p-4">
        <div class="w-full overflow-x-auto mx-6">
          <table class="w-full bg-transparent" mat-table matSort [dataSource]="jobPostingsDataSource"
            [trackBy]="trackByFn" #jobPostingsTable>

            <!-- Read -->
            <ng-container matColumnDef="read">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Worked
              </th>
              <td mat-cell *matCellDef="let jobPosting">
                <mat-icon *ngIf="jobPosting.read" svgIcon="check_box" color="primary"></mat-icon>
                <mat-icon *ngIf="!jobPosting.read" svgIcon="check_box_outline_blank" color="primary"></mat-icon>
              </td>
            </ng-container>

            <!-- Service -->
            <ng-container matColumnDef="service">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Service
              </th>
              <td mat-cell *matCellDef="let jobPosting">
                <img class="w-8 h-8" [matTooltip]="jobPosting.jobPostingType.name" src="{{jobPosting.jobPostingType.imageUrl}}" />
              </td>
            </ng-container>

            <!-- Reference ID -->
            <ng-container matColumnDef="referencId">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Ref. ID
              </th>
              <td mat-cell *matCellDef="let jobPosting">
                <button class="pr-6 text-secondary whitespace-nowrap" (click)="didClickJobPosting(jobPosting)">
                  {{jobPosting.referenceId}}
                </button>
              </td>
            </ng-container>

            <!-- Company -->
            <ng-container matColumnDef="company">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Company
              </th>
              <td mat-cell *matCellDef="let jobPosting">
                <span class="pr-6 text-secondary whitespace-nowrap">
                  {{jobPosting.jobPosting?.Company}}
                </span>
              </td>
            </ng-container>

            <!-- Product -->
            <ng-container matColumnDef="product">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Product
              </th>
              <td mat-cell *matCellDef="let jobPosting">
                <button *ngIf="!jobPosting.matchedLeadsProduct" class="pr-6 text-secondary text-sm whitespace-nowrap"
                (click)="didClickCreateProduct(jobPosting)">
                  Create Product
                </button>
                <a *ngIf="jobPosting.matchedLeadsProduct"
                routerLink="/companies/{{jobPosting.matchedLeadsProduct.company.id}}/matchedLeadsProducts/{{jobPosting.matchedLeadsProduct.id}}"
                >
                  Go to Product
                </a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="jobPostingsTableColumns"></tr>
            <tr class="order-row h-16" mat-row *matRowDef="let row; columns: jobPostingsTableColumns;"></tr>
            <!-- <tr
                  class="h-16 border-0"
                  mat-footer-row
                  *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
          </table>
        </div>
      </fuse-card>
    </div>


  </div>

</div>