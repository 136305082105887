<fuse-card class="flex flex-col w-full filter-article pt-5 pb-5 pl-8 pr-8">
  <div class="text-xl text-primary mb-2"><b>Personal Info</b></div>

  <!--first name-->
  <mat-form-field class="w-full">
    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
    <input matInput [(ngModel)]="application.firstName" [disabled]="true"
      placeholder="First Name">
  </mat-form-field>

  <!--last name-->
  <mat-form-field class="w-full">
    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
    <input matInput [(ngModel)]="application.lastName" [disabled]="true"
      placeholder="Last Name" [disabled]="true">
  </mat-form-field>

  <!--email-->
  <mat-form-field class="w-full">
    <mat-icon class="icon-size-5" matPrefix svgIcon="alternate_email"></mat-icon>
    <input matInput type="email" [(ngModel)]="application.email" [disabled]="true" placeholder="Email" [disabled]="true">
  </mat-form-field>

  <!--phone-->
  <mat-form-field class="w-full">
    <mat-icon class="icon-size-5" matPrefix svgIcon="phone"></mat-icon>
    <input type="tel" matInput [(ngModel)]="application.phone" [autocomplete]="'off'"
      placeholder="Phone" [disabled]="true">
  </mat-form-field>

  <!--zip-->
  <mat-form-field class="w-full">
    <mat-icon class="icon-size-5" matPrefix svgIcon="location_on"></mat-icon>
    <input matInput value="{{application.hiringZip.name}} ({{application.hiringZip.city}}, {{application.hiringZip.county}} county, {{application.hiringState.abbreviation}})" [autocomplete]="'off'" placeholder="Zip Code" [disabled]="true">
  </mat-form-field>

  <div class="text-xl text-primary mt-4 mb-2"><b>Tell us about your experience</b></div>
  <div>
    <div>
      <b>Which CDLs have you obtained?</b>
    </div>
    <div class="flex flex-col gt-sm:flex-row">
      <div class="flex flex-col">
        <div class="flex flex-row flex-wrap">
          <mat-checkbox *ngFor="let item of cdlClasses" class="p-3" [color]="'primary'" [(ngModel)]="item.checked" (change)="setCdlObtained(item)" [disabled]="true">
            {{item.name}}
          </mat-checkbox>
        </div>
      </div>
      <!-- Experience -->
      <div class="mt-4">
        <div>
          <b>How much verifiable experience do you have using your CDL?</b>
        </div>
        <mat-radio-group aria-label="Select experience" [disabled]="true">
          <mat-radio-button color="primary" class="p-3" *ngFor="let item of experience" value="{{item.id}}" [checked]="item.checked">
            {{item.name}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Endorsements -->
      <div class="mt-4" [hidden]="!shouldShowEndorsements()">
        <div><b>Do you have any endorsements?</b></div>
        <div class="flex flex-row flex-wrap">
          <mat-checkbox *ngFor="let item of endorsements" class="p-3" [color]="'primary'" [(ngModel)]="item.checked" (change)="setEndorsements(item)" [disabled]="true">
            {{item.name}}
          </mat-checkbox>
        </div>
      </div>

      <!-- Military -->
      <div class="mt-4">
        <div>
          <b>Do you have any military experience?</b>
        </div>
        <mat-radio-group aria-label="Select military experience" [disabled]="true">
          <mat-radio-button color="primary" class="p-3" value="true" [checked]="application.militaryExperience">YES</mat-radio-button>
          <mat-radio-button color="primary" class="p-3" value="false" [checked]="!application.militaryExperience">NO</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Violations -->
      <div class="mt-4">
        <div>
          <b>Number of moving violations in the last 3 years:</b>
        </div>
        <mat-radio-group aria-label="Select number of moving violations" [disabled]="true">
          <mat-radio-button color="primary" class="p-3" *ngFor="let item of violations" value="{{item.id}}" [checked]="item.checked">
            {{item.name}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Accidents -->
      <div class="mt-4">
        <div>
          <b>Number of preventable accidents in the last 3 years:</b>
        </div>
        <mat-radio-group aria-label="Select number of preventable accidents" [disabled]="true">
          <mat-radio-button color="primary" class="p-3" *ngFor="let item of accidents" value="{{item.id}}" [checked]="item.checked">
            {{item.name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Driver Type -->
      <div class="mt-4">
        <div>
          <b>Are you a Company Driver, Lease Purchase Driver, or an Owner Operator?</b>
        </div>
        <mat-radio-group aria-label="Select driver type" [disabled]="true">
          <mat-radio-button color="primary" class="p-3" *ngFor="let item of driverTypes" value="{{item.id}}" [checked]="item.checked">
            {{item.name}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- OO Interest -->
      <div class="mt-4" [hidden]="!shouldShowInterestInOO()">
        <div>
          <b>Are you interested in becoming an Owner Operator or hearing about Lease Purchase opportunities?</b>
        </div>
        <mat-radio-group aria-label="Select interest in Owner Operator" [disabled]="true">
          <mat-radio-button color="primary" class="p-3" value="true" [checked]="application.interestInOoLp">YES</mat-radio-button>
          <mat-radio-button color="primary" class="p-3" value="false" [checked]="!application.interestInOoLp">NO</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Has own authority -->
      <div class="mt-4" [hidden]="!shouldShowOwnAuthority()">
        <div>
          <b>Are you a Power Unit Only Owner Operator or do you have your Own Authority?</b>
        </div>
        <mat-radio-group aria-label="Select has own authority" [disabled]="true">
          <mat-radio-button color="primary" class="p-3" value="false" [checked]="application.hasOwnAuthority">Power Unit Only</mat-radio-button>
          <mat-radio-button color="primary" class="p-3" value="true" [checked]="!application.hasOwnAuthority">Own Authority</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Solo / Team -->
      <div class="mt-4">
        <div>
          <b>Are you a Solo Driver or a Team Driver?</b>
        </div>
        <mat-radio-group aria-label="Select driver type" [disabled]="true">
          <mat-radio-button color="primary" class="p-3" *ngFor="let item of soloTeam" value="{{item.id}}" (click)="setSoloTeam(item.id)" [checked]="item.checked">{{item.name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Interst in team driver -->
      <div class="mt-4" [hidden]="!shouldShowInterestInTeamDriving()">
        <div>
          <b>Would you be interested in becoming a team driver or matching up with another solo driver if it meant you would make more money and run in nicer equipment?</b>
        </div>
        <mat-radio-group aria-label="Select interest in team driver" [disabled]="true">
          <mat-radio-button color="primary" class="p-3" value="true" [checked]="application.interestInTeamDriving">YES</mat-radio-button>
          <mat-radio-button color="primary" class="p-3" value="false" [checked]="!application.interestInTeamDriving">NO</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- common freight -->
      <div class="mt-4">
        <div>
          <b>What type of freight do you most commonly haul?</b>
        </div>
        <mat-radio-group aria-label="Select common freight type" [disabled]="true">
          <mat-radio-button color="primary" class="p-3" *ngFor="let item of commonFreight" value="{{item.id}}" [checked]="item.checked">{{item.name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- interested freight -->
      <div class="mt-4">
        <div><b>What other freight types would you be interested in hauling?</b></div>
        <div class="flex flex-row flex-wrap">
          <mat-checkbox *ngFor="let item of interestedFreight" class="p-3" [color]="'primary'" [(ngModel)]="item.checked" (change)="setInterestedFreight(item)" [disabled]="true">
            {{item.name}}
          </mat-checkbox>
        </div>
      </div>

      <!-- Contact time -->
      <div class="mt-4">
        <div>
          <b>What is the best time to contact you?</b>
        </div>
        <mat-radio-group aria-label="Select contact time type" [disabled]="true">
          <mat-radio-button color="primary" class="p-3" *ngFor="let item of contactTimes" value="{{item.id}}" [checked]="item.checked">
            {{item.name}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

    </div>
  </div>
</fuse-card>