import { BaseLookup } from "./base.lookup";

export class WebhookAtsFieldsLookup extends BaseLookup {
    field: string;

    constructor(id: string, name: string, field: string) {
        super(id, name);
        this.field = field;
    }

  // Values
  static readonly CUSTOM = new WebhookAtsFieldsLookup('0', 'Custom', 'custom');
  static readonly FIRST_NAME = new WebhookAtsFieldsLookup('1', 'First Name', 'firstName');
  static readonly LAST_NAME = new WebhookAtsFieldsLookup('2', 'Last Name', 'lastName');
  static readonly EMAIL = new WebhookAtsFieldsLookup('4', 'Email', 'email');
  static readonly PHONE = new WebhookAtsFieldsLookup('5', 'Phone', 'phone');

  static readonly CDL_CLASSES = new WebhookAtsFieldsLookup('6', 'CDL Classes', 'cdlClasses');
  static readonly EXP_TYPE = new WebhookAtsFieldsLookup('7', 'Experience Type', 'expType');
  static readonly ENDORSEMENTS = new WebhookAtsFieldsLookup('8', 'Endorsements', 'endorsements');
  static readonly MILITARY = new WebhookAtsFieldsLookup('9', 'Military Experience', 'militaryExperience');
  static readonly VIOLATIONS = new WebhookAtsFieldsLookup('10', 'Violations', 'violations');
  static readonly ACCIDENTS = new WebhookAtsFieldsLookup('11', 'Accidents', 'accidents');
  static readonly DRIVER_TYPE = new WebhookAtsFieldsLookup('12', 'Driver Type', 'driverType');
  static readonly HAS_OWN_AUTHORITY = new WebhookAtsFieldsLookup('13', 'Has Own Authority', 'hasOwnAuthority');
  static readonly SOLO_OR_TEAM = new WebhookAtsFieldsLookup('14', 'Solo or Team', 'soloOrTeam');
  static readonly INTEREST_OOLP = new WebhookAtsFieldsLookup('15', 'Interest in OO/LP', 'interestInOoLp');
  static readonly INTEREST_TEAM = new WebhookAtsFieldsLookup('16', 'Interest in Team Driving', 'interestInTeamDriving');
  static readonly FREIGHT_TYPE = new WebhookAtsFieldsLookup('17', 'Common Freight Type', 'freightType');
  static readonly INTEREST_FREIGHT_TYPE = new WebhookAtsFieldsLookup('18', 'Interested Freight Types', 'freightTypes');
  static readonly CONTACT_TIME = new WebhookAtsFieldsLookup('19', 'Contact Time', 'contactTime');

  static readonly CITY = new WebhookAtsFieldsLookup('20', 'City', 'city');
  static readonly STATE = new WebhookAtsFieldsLookup('21', 'State', 'state');
  static readonly ZIP = new WebhookAtsFieldsLookup('22', 'Zip', 'zip');

  static readonly values = [
    WebhookAtsFieldsLookup.CUSTOM,
    WebhookAtsFieldsLookup.FIRST_NAME,
    WebhookAtsFieldsLookup.LAST_NAME,
    WebhookAtsFieldsLookup.EMAIL,
    WebhookAtsFieldsLookup.PHONE,
    WebhookAtsFieldsLookup.CDL_CLASSES,
    WebhookAtsFieldsLookup.EXP_TYPE,
    WebhookAtsFieldsLookup.ENDORSEMENTS,
    WebhookAtsFieldsLookup.MILITARY,
    WebhookAtsFieldsLookup.VIOLATIONS,
    WebhookAtsFieldsLookup.ACCIDENTS,
    WebhookAtsFieldsLookup.DRIVER_TYPE,
    WebhookAtsFieldsLookup.HAS_OWN_AUTHORITY,
    WebhookAtsFieldsLookup.SOLO_OR_TEAM,
    WebhookAtsFieldsLookup.INTEREST_OOLP,
    WebhookAtsFieldsLookup.INTEREST_TEAM,
    WebhookAtsFieldsLookup.FREIGHT_TYPE,
    WebhookAtsFieldsLookup.INTEREST_FREIGHT_TYPE,
    WebhookAtsFieldsLookup.CONTACT_TIME,
    WebhookAtsFieldsLookup.CITY,
    WebhookAtsFieldsLookup.STATE,
    WebhookAtsFieldsLookup.ZIP
  ]
}