import { IdentifiableDto } from "./identifiable.dto";
import { JobPostingTypeDto } from "./lookup.dto";
import { MatchedLeadsProductDto } from "./matchedLeadsProduct.dto";

export class JobPostingDto extends IdentifiableDto {
    timestampCreated!: Date;
    referenceId: string;
    read: boolean;
    jobPosting: any;

    //relations
    jobPostingType: JobPostingTypeDto;
    matchedLeadsProduct: MatchedLeadsProductDto;
}