import { ExternalJobPositionDto } from "./externalJobPosition.dto";
import { HiringCityDto } from "./hiringCity.dto";
import { IdentifiableDto } from "./identifiable.dto";
import { CdlClassDto, DriverTypeDto, EndorsementTypeDto, ExperienceTypeDto, FreightTypeDto, HiringStateDto, HiringZipDto } from "./lookup.dto";

export class ExternalJobDto extends IdentifiableDto {
    //core
    title!: string;
    notes: string;
    timestampPosted!: Date;
    timestampCreated!: Date;
    timestampUpdated!: Date;
    descriptionHtml!: string;
    inquiryWebsite: string;
    customApplyUrl: string;
    customSeoTitle: string;
    teamDriver!: boolean;
    visible: boolean;
    twilioNumber: string;
    twilioForwardNumber: string;
    tenstreetReferrer: string;
    accidents: number;
    violations: number;
    interestInTeamDriving: boolean;
    freightMatching: boolean;
    ownAuthority: boolean;
    jobcastReferenceId: string;
    customExperienceLevels: boolean;
    tenstreetJobPostingReferenceId: string;
    salary: string;

    //types
    experienceType: ExperienceTypeDto;
    driverTypes: DriverTypeDto[] = [];
    freightTypes: FreightTypeDto[] = [];
    cdlClasses: CdlClassDto[] = [];
    endorsements: EndorsementTypeDto[] = [];
    customExperienceTypes: ExperienceTypeDto[] = [];

    //positions
    positions: ExternalJobPositionDto[] = [];


    //internal
    checked: boolean = false;
}