import { TenstreetJobPostingEndorsements } from './tenstreetJobPostingEndorsements';
import { TenstreetJobPostingJobFilters } from './tenstreetJobPostingJobFilters';
import { TenstreetJobPostingLocations } from './tenstreetJobPostingLocations';

export class TenstreetJobPosting {
    Company: string;
    CompanyId: number;
    ChildCompanyId: string;
    CompanyContactName: string;
    CompanyContactPhone: string;
    OrderId: number;
    ReferenceId: string;
    AutoOrder: string;
    AutoOrderId: string;
    NextMonthAutoOrder: string;
    JobTitle: string;
    BasePackagePrice: number;
    Price: number;
    Budget: number;
    AppCount: string;
    ProrateModifier: number;
    StartDate: string;
    EndDate: string;
    PostingType: string;
    JobDescription: string;
    JobRequirements: string;
    JobEmailContent: string;
    ApplyLinkURL: string;
    LogoURL: string;
    JobPayRange: string;
    JobGeneralBenefits: string;
    JobFilters: TenstreetJobPostingJobFilters;
    Endorsements: TenstreetJobPostingEndorsements;
    Locations: TenstreetJobPostingLocations;
}
